/* eslint-disable react/button-has-type */

import arrowImg from '../../assets/arrow.svg';

import { backdropBlur } from '../styles';


const style = css`
	display: grid;
	grid-template-areas:
		"a     up     b"
		"left  c  right"
		"d    down    e";
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	pointer-events: all;
	width: 80px;
	height: 80px;

	> div {
		border: solid 1px black;
		${backdropBlur}
	}
	> div:first-child,
	> div:last-child {
		margin: -1px;
	}

	button {
		width: 100%;
		height: 100%;
		background: transparent;
		border: none;
		padding: 0;
	}

	img {
		height: 12px;
	}
`;


let keyIsDown = false;

const PanControls = (props) => {
	const handler = (dir) => (event) => {
		keyIsDown = true;
		const id = setInterval(
			() => {
				if (!keyIsDown) {
					clearInterval(id);
					return;
				}
				window.controls.pan(dir);
			},
			1000 / 60,
		);
	};
	const done = (event) => {
		keyIsDown = false;
	};

	return <div className={style}>
		<div className={css`grid-area: up; img { transform: rotateZ(-90deg); }`}>
			<button onMouseUp={done} onMouseDown={handler('up')}>
				<img src={arrowImg} alt="up" />
			</button>
		</div>
		<div className={css`grid-area: left; img { transform: rotateZ(-180deg); }`}>
			<button onMouseUp={done} onMouseDown={handler('left')}>
				<img src={arrowImg} alt="left" />
			</button>
		</div>
		<div className={css`grid-area: right;`}>
			<button onMouseUp={done} onMouseDown={handler('right')}>
				<img src={arrowImg} alt="right" />
			</button>
		</div>
		<div className={css`grid-area: down; img { transform: rotateZ(90deg); }`}>
			<button onMouseUp={done} onMouseDown={handler('down')}>
				<img src={arrowImg} alt="down" />
			</button>
		</div>
	</div>;
};

PanControls.propTypes = {
	// zoomEnabled: PropTypes.bool.isRequired,
};

// PanControls.defaultProps = {
// };

export default PanControls;
