import xImg from '../../assets/x.svg';

import { margins } from '../styles';


const CloseButton = ({ extraStyles, onClick, id }) => {
	return <div
		onClick={onClick}
		id={id}
		className={cx(
			css`
				cursor: pointer;
				padding: ${margins.s};
				margin-top: 8px;
				margin-right: 8px;
				display: inline-block;
				img {
					display: block;
				}
			`,
			extraStyles,
		)}
	>
		<img src={xImg} alt="close" />
	</div>;
};

CloseButton.propTypes = {
	extraStyles: PropTypes.any,
	onClick: PropTypes.func,
	id: PropTypes.string,
};

export default CloseButton;
