import R from 'ramda';

import countries from '../data/countries.json';


export const fieldNames = {
	title: 'TITLE',
	names: 'names',
	description: 'description',
	years: 'years',
	tags: 'tags',
	images: 'images',
	website: 'Website',
	otherInfoLink: 'other Info (links)',
	partners: 'Partners name & status',
	onlinePublication: 'Online publications',
	pdfPublication: 'pdfPublication',
	videos: 'videos',
};


const addHttp = (url) => {
	if (!url) { return url; }
	if (!/^https?:\/\//.test(url)) {
		return `http://${url}`;
	}
	return url;
};


export const getWebsite = (item) => addHttp(item[fieldNames.website]);
export const getOnlinePublication = (item) => addHttp(item[fieldNames.onlinePublication]);
export const getPdfPublication = (item) => item[fieldNames.pdfPublication];
export const getOtherInfoLink = (item) => addHttp(item[fieldNames.otherInfoLink]);
export const getTitle = (item) => item[fieldNames.title];
export const getNames = (item) => item[fieldNames.names];
export const getPartners = (item) => item[fieldNames.partners];
export const getImages = (item) => item[fieldNames.images] || [];
export const getVideos = (item) => item[fieldNames.videos] || [];
export const getYears = (item) => {
	return R.pipe(
		R.sortBy(R.identity),
		// R.reverse,
	)(item[fieldNames.years]);
};
export const getDescription = (item) => item[fieldNames.description];
export const getLocations = (item) => {
	return item.countries
		.map((countryCode) => (countries[countryCode] || {}).name)
		.filter(R.identity);
};
