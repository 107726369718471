/* eslint-disable no-console */
import R from 'ramda';
import * as THREE from 'three';
import {
	interpolate as d3Interpolate,
	interpolateArray as d3InterpolateArray,
} from 'd3-interpolate';
import { easeQuadOut as d3EaseQuadOut } from 'd3-ease';
import { geoMercator as d3GeoMercator } from 'd3-geo';
import * as StackBlur from 'stackblur-canvas';
import {
	forceSimulation,
	forceManyBody,
	forceLink,
	forceCenter,
	forceZ,
} from 'd3-force-3d';
import createTextGeometry from 'three-bmfont-text';
import MSDFShader from 'three-bmfont-text/shaders/msdf';
import queryString from 'query-string';
import throttle from 'lodash.throttle';
import TWEEN from '@tweenjs/tween.js';

import ThreeOrbitControls from '../libs/three-orbit-controls';
import shader from '../shaders/dots';
import textShader from '../shaders/text';
import paths from '../paths';
import {
	getNodeId,
	getNodeColor,
	getNodeBgColor,
	getLastPathPart,
	getGridPositionHorizontal,
} from '../utils';
import idToLabel from '../label-lookup';
import {
	setContent,
	setEnabledInteractions,
} from '../actionCreators';
import {
	responsizeFontSize,
	margins,
	backdropBlur,
	blueKlein,
} from '../styles';
import { getTitle } from '../data';

import countries from '../../data/countries.json';
import fontJson from '../../assets/fonts/lato-msdf/lato-msdf.json';
import fontTexture from '../../assets/fonts/lato-msdf/lato.png';

import textureAtlasImg from '../../tools/texture-atlas.jpg';
import texAtlas from '../../tools/texture-atlas.json';

import ActivityContent from './ActivityContent';
import CloseButton from './CloseButton';


let countryOutlinesLoaded; // loaded on demand
const isFF = window.navigator.userAgent.toLowerCase().includes('firefox');


const textureLoader = new THREE.TextureLoader();
const OrbitControls = ThreeOrbitControls(THREE);
const SCALE = 0.3;
const TEXT_SCALE_FACTOR = 0.027;
const ORIGIN_NODE_ID = '__origin__';

// texture atlas
const texAtlasTileSize = 1 / texAtlas.rowLen;
const tex = textureLoader.load(textureAtlasImg);

const edgeLineMaterial = new THREE.LineBasicMaterial({
	color: 'rgb(0, 0, 0)',
	opacity: 0.19,
	transparent: true,
});


// const dontClear = false;
const rendererSettings = {
	antialias: true,
	alpha: true,
	preserveDrawingBuffer: true, // needed for 2d canvas
	// preserveDrawingBuffer: dontClear,
};
const withBlurryBg = true;
const withTextures = true;
const leafSubcategoriesOnly = false;

const displayCenterEdges = false;
const hideMainCatLabel = true;

// const topicsSimOptions = {
// 	chargeStrength: -60,
// 	linkDistance: 15,
// 	dimenstions: 2,
// };
const topicsSimOptions = {
	chargeStrength: -5,
	linkDistance: 50,
	dimenstions: 3,
};

const MAX_COUNT = 5000;
const camInitialPos = [0, 0, 52];


const pickingColorLabelLookUp = {};


const state = {
	showMetaNodes: false,
	showMetaNodeLabels: false,
	nodes: [],
	rotate: false,
	contentIsOpen: false,
};
window.prevPickingIndex = -1;


const hideVisibleObjects = (objList) => {
	const visibleObjects = objList.reduce(
		(acc, obj) => {
			if (obj && obj.visible) {
				obj.visible = false;
				return [...acc, obj];
			}
			return acc;
		},
		[],
	);
	return visibleObjects;
};


const isOriginNode = (node) => {
	return (node.id === ORIGIN_NODE_ID);
};


const isCenterNode = (node) => {
	return (node.fx === 0 && node.fy === 0 && node.fz === 0);
};


const getEdgeVertices = (link) => {
	const fromVec = new THREE.Vector3(
		link.source.x * SCALE,
		link.source.y * SCALE,
		link.source.z * SCALE,
	);
	const toVec = new THREE.Vector3(
		link.target.x * SCALE,
		link.target.y * SCALE,
		link.target.z * SCALE,
	);
	const dir = toVec.clone().sub(fromVec).normalize();
	const otherDir = dir.clone().negate();
	const f = 1;
	return [
		fromVec.add(dir.multiplyScalar(f)),
		toVec.add(otherDir.multiplyScalar(f)),
	];
};


const makeTextMaterial = (isInitialLetter = false) => {
	const msdfShader = MSDFShader({
		map: textureLoader.load(fontTexture),
		transparent: true,
		color: (isInitialLetter) ? blueKlein : 'black',
		negate: false,
	});
	msdfShader.vertexShader = textShader.vertex;
	msdfShader.fragmentShader = textShader.fragment;
	msdfShader.uniforms = {
		...msdfShader.uniforms,
		usePicking: { type: 'f', value: 0.0 },
		pickingColor: { type: 'c', value: [0, 0, 0] },
	};
	const textMaterial = new THREE.RawShaderMaterial(msdfShader);
	return textMaterial;
};


const createTextObject = (text, scaleFactor = 1, isInitialLetter = false) => {
	const geom = createTextGeometry({
		text,
		font: fontJson,
		align: 'left',
		flipY: true,
		// flipY: texture.flipY,
	});
	// geom.computeBoundingBox();

	// const spriteMaterial = new THREE.SpriteMaterial({
	// 	sizeAttenuation: false,
	// 	map: textureLoader.load(fontTexture),
	// 	// color: 0xffffff
	// });
	// const obj = new THREE.Sprite(spriteMaterial);
	// obj.geometry = geom;
	// obj.geometry.attributes.position.needsUpdate = true;
	// obj.geometry.attributes.uv.needsUpdate = true;

	// const mat = textMaterial.clone();
	const mat = makeTextMaterial(isInitialLetter);
	// mat.uniforms = THREE.UniformsUtils.clone(textMaterial.uniforms);
	// mat.uniforms = THREE.UniformsUtils.merge([
	// 	textMaterial.uniforms,
	// 	{
	// 		color: { value: [1.0, 0.0, 0.0] },
	// 		usePicking: { value: 0 },
	// 		pickingColor: {
	// 			value: [
	// 				Math.random(),
	// 				Math.random(),
	// 				Math.random(),
	// 			],
	// 		},
	// 	},
	// ]);s

	const obj = new THREE.Mesh(geom, mat);
	obj.rotation.x = Math.PI;
	obj.scale.set(
		TEXT_SCALE_FACTOR * scaleFactor,
		TEXT_SCALE_FACTOR * scaleFactor,
		1,
	);

	const textAnchor = new THREE.Object3D();
	textAnchor.add(obj);

	// const { layout } = geom;
	// obj.position.set(0.8 * layout.height, -0.4 * layout.height, 0);

	// always display on top
	// https://stackoverflow.com/a/13309722/2839801
	// obj.renderOrder = 999;
	// obj.onBeforeRender = (renderer) => {
	// 	renderer.clearDepth();
	// };

	const dummy = new THREE.Object3D();
	dummy._childObject = textAnchor;
	return dummy;
};


const getMetaNodes = R.once(
	() => {
		const metaNodes = {
			categories: R.keys(window.dataByCategory)
				.map((cat) => ({
					id: cat,
					label: idToLabel[cat] || cat,
					meta: true,
				})),
			'sub-categories': [],
			years: [],
			tags: [],
		};

		let seenIds = [];
		window.allDataItems.forEach((it) => {
			const mainCat = R.head(it.categories);
			if (!metaNodes['sub-categories'][mainCat]) {
				metaNodes['sub-categories'][mainCat] = [];
			}
			if (it.categories.length < 2) {
				return;
			}
			const subCats = (leafSubcategoriesOnly)
				? [R.last(it.categories)]
				: R.tail(it.categories);

			const newIds = subCats.map((cat, i) => {
				// if (!idToLabel[cat]) {
				// 	console.log(cat);
				// }
				const id = R.take(i + 2, it.categories).join('__');
				if (!seenIds.includes(id)) {
					seenIds = [...seenIds, id];
					metaNodes['sub-categories'][mainCat] = [
						...metaNodes['sub-categories'][mainCat],
						{
							id,
							label: idToLabel[cat] || cat,
							isSubCat: true,
							meta: true,
						},
					];
				}
				return id;
			});
			it.categories = [mainCat, ...newIds];
		});

		window.allDataItems
			.forEach((it) => {
				['years', 'tags'].forEach((key) => {
					it[key] = it[key] || [];
					it[key].forEach((value) => {
						if (!seenIds.includes(value)) {
							metaNodes[key] = [
								...metaNodes[key],
								{ id: value, meta: true },
							];
							seenIds = [...seenIds, value];
						}
					});
				});
			});

		return metaNodes;
	},
);


const prepData = (
	allItems,
	categoriesFilter = [],
	metaNodesFilter = [],
	connectNodesToCenter = true,
	selectedTagId = null,
) => {
	let tagIds;
	if (selectedTagId) {
		/* eslint-disable-next-line no-param-reassign */
		tagIds = R.pipe(
			R.map(R.prop('tags')),
			R.filter(
				(tags) => tags.includes(selectedTagId),
			),
			R.unnest,
			R.uniq,
		)(allItems);
	}

	const metaNodesByType = getMetaNodes();
	let metaNodes = null;

	const centerNode = {
		id: ORIGIN_NODE_ID,
		meta: true,
		fx: 0, // fixed position
		fy: 0,
		fz: 0,
	};

	let hasNoSubcategories;

	if (metaNodesFilter.includes('sub-categories')) {
		// also include main category
		metaNodes = [
			...R.pipe(
				R.map((cat) => {
					if (
						!metaNodesByType['sub-categories'][cat]
						|| !metaNodesByType['sub-categories'][cat].length
					) {
						hasNoSubcategories = true;
					}
					const mainCatNode = R.find(
						R.propEq('id', cat),
						metaNodesByType.categories,
					);
					if (!mainCatNode) {
						return null;
					}
					return {
						...mainCatNode,
						fx: 0, // fixed position
						fy: 0,
						fz: 0,
					};
				}),
				R.reject((n) => !n),
			)(categoriesFilter),

			...R.pipe(
				R.map((cat) => metaNodesByType['sub-categories'][cat]),
				R.unnest,
				R.reject((n) => !n),
			)(categoriesFilter),
		];
	} else {
		metaNodes = R.pipe(
			R.map((key) => metaNodesByType[key]),
			R.unnest,
		)(metaNodesFilter);
		if (selectedTagId) {
			metaNodes = metaNodes.filter((node) => {
				if (tagIds.includes(node.id)) {
					return true;
				}
				return false;
			});
		}
	}

	if (state.pathname === paths.TOPICS && !selectedTagId) {
		let initialLetterLinks = [];
		const allTags = R.pipe(
			R.map(R.prop('tags')),
			R.unnest,
			R.uniq,
		)(allItems);
		const tagsByInitialLetter = R.groupBy(
			R.pipe(R.head, R.toLower),
		)(allTags);
		const letters = R.keys(tagsByInitialLetter);
		let labelNodes = [];
		const makeInitialLetterId = (letter) => `__letter-${letter}`;
		const letterNodes = R.pipe(
			R.toPairs,
			R.sortBy(R.head),
			R.map(([letter, tags]) => {
				tags.forEach((tag) => {
					labelNodes = [
						...labelNodes,
						{
							meta: true,
							id: tag,
						},
					];
				});
				return {
					isInitialLetter: true,
					meta: true,
					id: makeInitialLetterId(letter),
					label: R.toUpper(letter),
				};
			}),
		)(tagsByInitialLetter);
		metaNodes = [
			// ...metaNodes,
			...labelNodes,
			...letterNodes,
			centerNode,
		];
		letterNodes.forEach((node) => {
			initialLetterLinks = [
				...initialLetterLinks,
				{ source: centerNode.id, target: node.id },
			];
		});

		letters.forEach((letter) => {
			tagsByInitialLetter[letter].forEach((tag) => {
				initialLetterLinks = [
					...initialLetterLinks,
					{ source: makeInitialLetterId(letter), target: tag },
				];
			});
		});

		return {
			nodes: metaNodes,
			links: initialLetterLinks,
			// shouldConnectToCenter: hasNoSubcategories,
		};
	}

	const isSearch = state.pathname === paths.SEARCH;
	if (isSearch) {
		metaNodes = [
			{
				meta: true,
				id: `search: ${window.query}`,
				label: window.query,
				fx: 0,
				fy: 0,
				fz: 0,
			},
		];
	}

	let links = [];
	const regularNodes = allItems
		.map((node, i) => {
			if (!node.id) {
				// make sure there are no duplicate ids
				node.id = `${i}__${getNodeId(node)}`;
			}
			let nodeLinks = [];
			let catLinks = [];

			const isActiveCat = categoriesFilter.includes(node.categories[0]);
			node.isVisible = (
				(
					(categoriesFilter.length === 0)
					&& !(metaNodesFilter.includes('tags') && !node.tags.length)
				)
				|| isActiveCat
			);
			if (selectedTagId) {
				if (!node.tags.includes(selectedTagId)) {
					node.isVisible = false;
				}
			}
			if (!node.isVisible) {
				return null;
			}

			R.without(['categories', 'sub-categories'], metaNodesFilter)
				.forEach((key) => {
					node[key].forEach((value) => {
						if (selectedTagId && key === 'tags' && !tagIds.includes(value)) {
							return;
						}
						nodeLinks = [
							...nodeLinks,
							{ source: node.id, target: value },
						];
					});
				});

			if (metaNodesFilter.includes('sub-categories')) {
				/* eslint-disable-next-line */
				let reverseCats = R.reverse(node.categories);
				if (node.categories.length > 1) {
					if (leafSubcategoriesOnly) {
						// TODO: DRY, this is already done in getMetaNodes
						reverseCats = [
							R.head(reverseCats),
							R.last(reverseCats),
						];
					}

					const zipped = R.zip(reverseCats, R.tail(reverseCats));
					zipped.forEach(([cat, parentCat]) => {
						catLinks = [
							...catLinks,
							{ source: cat, target: parentCat },
						];
					});
				}
				if (connectNodesToCenter) {
					nodeLinks = [
						...nodeLinks,
						{ source: node.id, target: reverseCats[0] },
					];
				}
			} else if (metaNodesFilter.includes('categories')) {
				if (connectNodesToCenter) {
					nodeLinks = [
						...nodeLinks,
						{ source: node.id, target: node.categories[0] },
					];
				}
			}

			// const hasNoLinks = !nodeLinks.length;
			// if (hasNoLinks) {
			// 	console.log('has no edges:', node);
			// 	return null;
			// }

			links = [
				...links,
				...nodeLinks,
				...catLinks,
			];

			if (!metaNodesFilter.includes('sub-categories')) {
				if (connectNodesToCenter) {
					links = [
						...links,
						{ source: node.id, target: centerNode.id },
					];
				}
			}

			return node;
		})
		.filter(R.identity);

	if (isSearch) {
		regularNodes.forEach((node) => {
			links = [
				...links,
				{ source: node.id, target: metaNodes[0].id },
			];
		});
	}

	let finalLinks = [];
	links.forEach((link) => {
		const existingLink = R.find(
			(l) => {
				return (
					((l.source === link.source) && (l.target === link.target))
					|| ((l.source === link.target) && (l.target === link.source))
				);
			},
			finalLinks,
		);
		if (!existingLink) {
			finalLinks = [...finalLinks, link];
		}
	});

	// console.log(`regular: ${regularNodes.length} -- meta: ${metaNodes.length}`);
	let finalNodes = [
		...metaNodes,
		...regularNodes,
	];
	if (!metaNodesFilter.includes('sub-categories')) {
		if (connectNodesToCenter) {
			finalNodes = [
				...finalNodes,
				centerNode,
			];
		}
	}

	return {
		nodes: finalNodes,
		links: finalLinks,
		shouldConnectToCenter: isSearch || hasNoSubcategories,
	};
};


const makeSimulation = (nodes, links, ticked, options = {}) => {
	const sim = forceSimulation(nodes, 3)
		.force(
			'charge',
			forceManyBody()
				.strength((node) => {
					if (state.pathname === paths.IMPACT) {
						return -10; /*(node.fx === 0)
							? 30
							: -20;*/
					}
					return options.chargeStrength || -30;
				}),
		)
		.force(
			'link',
			forceLink(links)
				.id(getNodeId)
				.distance((link) => {
					// if (state.pathname === paths.IMPACT) {
					// 	return (link.source.fx === 0 || link.target.fx === 0)
					// 		? -5
					// 		: 30;
					// }
					return options.linkDistance || 30;
				}),
		)
		.force('center', forceCenter())
		.on('tick', ticked);

	if (options.dimenstions === 2) {
		sim.force(
			'z',
			forceZ(0).strength(2),
		);
	}

	return sim;
};


class ThreeCanvas extends Component {
	constructor(props) {
		super(props);

		const parsed = queryString.parse(window.location.search);
		this.debug = Boolean(parsed.debug);

		function start() {
			if (!this.frameId) {
				this.frameId = requestAnimationFrame(this.update);
			}
		}

		function stop() {
			cancelAnimationFrame(this.frameId);
		}

		function update() {
			const now = performance.now();
			// this.material.uniforms.time.value = now;
			if (state.rotate) {
				// this.material.uniforms.rotation.value = now * 0.00025;
				this.rotationGroup.rotation.y = now * 0.00025;
				this.updateLabelPositions();
			}

			this.renderScene();
			this.frameId = window.requestAnimationFrame(this.update);
		}

		let firstRender = true;
		function renderScene() {
			TWEEN.update();

			// reset picking
			this.renderer.setRenderTarget(null);
			this.material.uniforms.usePicking.value = 0.0;
			R.values(this.idToTextObj).forEach((textObj) => {
				textObj._childObject.children[0].material.uniforms.usePicking.value = 0.0;
			});

			if (withBlurryBg) {
				// render once – without labels, lines, and textures
				const visibleObjects = hideVisibleObjects([
					...R.values(this.idToTextObj)
						.map(R.prop('_childObject')),
					this.countriesGroup,
					this.lineSegments,
				]);

				this.material.uniforms.useTexture.value = 0;
				this.material.uniforms.useDotColor.value = 1;
				this.material.uniforms.showOutline.value = 0;
				this.renderer.render(this.scene, this.camera);

				const w = this.ctx.canvas.width;
				const h = this.ctx.canvas.height;
				const alpha = (firstRender)
					? 1.0
					: 0.03;
				this.ctx.fillStyle = `rgba(255, 255, 255, ${alpha})`;
				this.ctx.fillRect(0, 0, w, h);
				this.ctx.drawImage(this.renderer.domElement, 0, 0, w, h);
				if (isFF) {
					// TODO: make blur radius relative to resolution
					StackBlur.canvasRGB(this.ctx.canvas, 0, 0, w, h, 5);
				}

				// show objects again
				visibleObjects.forEach((item) => {
					item.visible = true;
				});

				firstRender = false;
			}

			// render actual scene
			this.material.uniforms.useTexture.value = state.useTexture;
			this.material.uniforms.useDotColor.value = state.useDotColor;
			this.material.uniforms.showOutline.value = state.showOutline;

			// this.material.uniforms.usePicking.value = 1.0;
			// R.values(this.idToTextObj).forEach((textObj) => {
			// 	textObj._childObject.children[0].material.uniforms.usePicking.value = 1.0;
			// });

			// const visibleObjects = hideVisibleObjects([
			// 	this.countriesGroup,
			// 	this.lineSegments,
			// ]);
			this.renderer.render(this.scene, this.camera);
			// visibleObjects.forEach((item) => {
			// 	item.visible = true;
			// });
		}

		this.start = start.bind(this);
		this.stop = stop.bind(this);
		this.update = update.bind(this);
		this.renderScene = renderScene.bind(this);

		// this.initUI = this.initUI.bind(this);
	}

	componentDidMount() {
		// this.initUI();

		const width = this.mount.clientWidth;
		const height = this.mount.clientHeight;

		this.scene = new THREE.Scene();

		// based on https://stackoverflow.com/a/15735090/2839801
		// text labels are not part of the rotation group. they get their positions
		// from dummy objects, and their orientation from the camera.
		// this also means, we have to maintain a separate rotation group for the
		// picking scene as well.
		this.rotationGroup = new THREE.Group();
		this.scene.add(this.rotationGroup);

		this.camera = new THREE.PerspectiveCamera(
			70,
			width / height,
			0.1,
			1000,
		);
		this.camera.position.x = camInitialPos[0];
		this.camera.position.y = camInitialPos[1];
		this.camera.position.z = camInitialPos[2];

		this.renderer = new THREE.WebGLRenderer(rendererSettings);
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(width, height);
		this.renderer.domElement.id = 'main-canvas';
		this.renderer.domElement.style.transition = 'opacity 0.15s';
		this.mount.appendChild(this.renderer.domElement);
		this.renderer.domElement.addEventListener('click', (event) => {
			event.stopPropagation();
			if (state.contentIsOpen) {
				this.props.dispatch(
					setContent({ content: null }),
				);
			}
		});
		this.renderer.domElement.addEventListener('mousedown', () => {
			state.rotate = false;
		});

		// this.renderer.setClearColor(bgColor);
		// this.renderer.autoClearColor = !dontClear;
		// this.renderer.autoClearColor = false;
		// this.renderer.clear();

		window.controls = this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.maxDistance = 200;
		this.controls.updateCb = () => {
			this.updateLabelPositions();
		};
		// if (process.env.NODE_ENV === 'production') {
		// 	this.controls.enabled = false;
		// }

		// https://threejs.org/examples/#webgl_buffergeometry_instancing_billboards
		const nodeGeometry = new THREE.CircleBufferGeometry(0.7, 30);
		// const nodeGeometry = new THREE.PlaneBufferGeometry(1.5, 1.5);
		const geometry = new THREE.InstancedBufferGeometry();
		geometry.index = nodeGeometry.index;
		geometry.attributes = nodeGeometry.attributes;

		const translateComponents = 3;
		const colorComponents = 4;
		const translateArray = new Float32Array(MAX_COUNT * translateComponents);
		const colorArray = new Float32Array(MAX_COUNT * colorComponents);
		const pickingColorArray = new Float32Array(MAX_COUNT * 3);
		const uvOffsetArray = new Float32Array(MAX_COUNT * 2);
		this.idToTextObj = {};
		geometry.addAttribute(
			'translate',
			new THREE.InstancedBufferAttribute(translateArray, translateComponents),
		);
		geometry.attributes.translate.dynamic = true;
		geometry.addAttribute(
			'color',
			new THREE.InstancedBufferAttribute(colorArray, colorComponents),
		);
		geometry.addAttribute(
			'pickingColor',
			new THREE.InstancedBufferAttribute(pickingColorArray, 3),
		);
		geometry.addAttribute(
			'uvOffset',
			new THREE.InstancedBufferAttribute(uvOffsetArray, 2),
		);

		// const visibleHeightAtDistance = (dist, camera) => {
		// 	// vertical fov in radians
		// 	const vFOV = camera.fov * Math.PI / 180;
		// 	// Math.abs to ensure the result is always positive
		// 	return 2 * Math.tan(vFOV / 2) * Math.abs(dist);
		// };

		const camDirection = new THREE.Vector3();
		this.updateLabelPositions = () => {
			this.camera.getWorldDirection(camDirection);

			state.nodes
				.forEach((node, i) => {
					geometry.attributes.translate.array[i * 3 + 0] = node.x;
					geometry.attributes.translate.array[i * 3 + 1] = node.y;
					geometry.attributes.translate.array[i * 3 + 2] = node.z;

					if (this.idToTextObj[node.id]) {
						const textObj = this.idToTextObj[node.id];

						textObj.position.set(
							node.x * SCALE,
							node.y * SCALE,
							node.z * SCALE,
						);

						// // get distance from camera plane (instead of just from camera)
						// const distFromCamPlaneVec = textObj.position.clone();
						// distFromCamPlaneVec.sub(this.camera.position);
						// distFromCamPlaneVec.projectOnVector(camDirection);
						// const dist = distFromCamPlaneVec.distanceTo(this.camera.position);
						// const scale = dist * TEXT_SCALE_FACTOR * 0.33;

						// const dist = textObj.position.distanceTo(this.camera.position);

						// const desiredHeight = 3.5;
						// const actualHeight = this.renderer.domElement.height / visibleHeightAtDistance(dist, this.camera);
						// const f = desiredHeight / actualHeight;
						// const scale = f * desiredHeight;

						// textObj._childObject.scale.set(scale, scale, 1);
						textObj._childObject.position.setFromMatrixPosition(textObj.matrixWorld);

						// face screen
						textObj._childObject.quaternion.copy(this.camera.quaternion);
					}
				});
		};

		this.updateArrays = () => {
			// create text nodes as needed:
			state.nodes.forEach((node) => {
				if (node.meta && !this.idToTextObj[node.id]) {
					let scaleFactor = 1;
					if (node.isInitialLetter) { scaleFactor = 4; }
					else if (node.isSubCat) { scaleFactor = 1.5; }
					const textObj = createTextObject(
						`${node.label || node.id}`,
						scaleFactor,
						node.isInitialLetter,
					);

					const upper = 16777214; // 0xfffffe
					const idx = upper - R.keys(this.idToTextObj).length;
					const hex = idx.toString(16);
					pickingColorLabelLookUp[hex] = textObj;
					const hexColorStr = `#${hex.padStart(6, '0')}`;
					const mat = textObj._childObject.children[0].material;
					mat.uniforms.pickingColor = {
						value: (new THREE.Color(hexColorStr)).toArray(),
					};
					mat.uniformsNeedUpdate = true;
					textObj._childObject.userData.node = node;

					this.idToTextObj[node.id] = textObj;
					textObj._childObject.visible = false;

					this.rotationGroup.add(textObj);
					this.scene.add(textObj._childObject);
				}
			});

			state.nodes.forEach((node, i) => {
				if (node.meta) {
					colorArray[(i * colorComponents) + 0] = 0.8;
					colorArray[(i * colorComponents) + 1] = 0.8;
					colorArray[(i * colorComponents) + 2] = 0.8;
					colorArray[(i * colorComponents) + 3] = (state.showMetaNodes)
						? 1.0
						: 0.0;
					const textObj = this.idToTextObj[node.id];
					if (textObj) {
						const isHiddenCenterNode = (isCenterNode(node) && hideMainCatLabel);
						textObj._childObject.visible = (isOriginNode(node) || isHiddenCenterNode)
							? false
							: state.showMetaNodeLabels;
						if (state.pathname === paths.SEARCH) {
							textObj._childObject.visible = true;
						}
					}
				} else {
					const color = getNodeColor(node);
					const c = new THREE.Color(color);
					colorArray[(i * colorComponents) + 0] = c.r;
					colorArray[(i * colorComponents) + 1] = c.g;
					colorArray[(i * colorComponents) + 2] = c.b;
					colorArray[(i * colorComponents) + 3] = (node.isVisible) ? 1.0 : 0.0;

					pickingColorArray[(i * 3) + 0] = node.pickingColor[0];
					pickingColorArray[(i * 3) + 1] = node.pickingColor[1];
					pickingColorArray[(i * 3) + 2] = node.pickingColor[2];

					uvOffsetArray[(i * 2) + 0] = node.uvOffset[0];
					uvOffsetArray[(i * 2) + 1] = node.uvOffset[1];
					// uvOffsetArray[(i * 3) + 2] = node.showImage;
				}
			});

			geometry.attributes.color.needsUpdate = true;
			geometry.attributes.pickingColor.needsUpdate = true;
			geometry.attributes.uvOffset.needsUpdate = true;

			geometry.maxInstancedCount = state.nodes.length;
		};

		this.ticked = () => {
			state.nodes.forEach((node, i) => {
				geometry.attributes.translate.array[i * 3 + 0] = node.x || 0;
				geometry.attributes.translate.array[i * 3 + 1] = node.y || 0;
				geometry.attributes.translate.array[i * 3 + 2] = node.z || 0;
			});
			geometry.attributes.translate.needsUpdate = true;

			if (this.lineSegments) {
				const posArray = this.lineSegments.geometry.attributes.position.array;
				state.links.forEach((link, i) => {
					const ii = i * 6;
					const verts = getEdgeVertices(link);
					const verts1 = verts[0].toArray();
					const verts2 = verts[1].toArray();
					posArray[ii + 0] = verts1[0];
					posArray[ii + 1] = verts1[1];
					posArray[ii + 2] = verts1[2];
					posArray[ii + 3] = verts2[0];
					posArray[ii + 4] = verts2[1];
					posArray[ii + 5] = verts2[2];
				});
				this.lineSegments.geometry.attributes.position.needsUpdate = true;
			}

			this.updateLabelPositions();
		};

		this.flyToCoords = (coords) => {
			const cam = this.camera;
			const camViewDirection = new THREE.Vector3(0, 0, -1);
			camViewDirection.applyQuaternion(cam.quaternion);

			const currentCamPos = cam.position.clone();
			const destination = new THREE.Vector3();
			destination.fromArray(coords);

			const distanceToTravel = destination.clone().sub(currentCamPos).length();
			const tweenDuration = distanceToTravel * (2 * 2500 / 200);
			const p0 = currentCamPos.clone();
			const c0 = currentCamPos.clone()
				.add(
					camViewDirection.clone().multiplyScalar(10),
				);
			const destToStartDirection = p0.clone().sub(destination).normalize();
			const p1 = destination.clone()
				.add(
					destToStartDirection.clone().multiplyScalar(10 * 2),
				);
			const c1 = p1.clone()
				.add(
					destToStartDirection.clone().multiplyScalar(10),
				);
			const curve = new THREE.CubicBezierCurve3(p0, c0, c1, p1);

			const interpOrigin = d3InterpolateArray(
				this.controls.target.toArray(),
				coords, // [0, 0, 0]
			);
			const target = new THREE.Vector3();

			return new TWEEN.Tween({ t: 0 })
				.to({ t: 1 }, tweenDuration)
				.easing(TWEEN.Easing.Quadratic.InOut)
				.onUpdate(({ t }) => {
					const curvePoint = curve.getPointAt(t);
					cam.position.set(
						curvePoint.x,
						curvePoint.y,
						curvePoint.z,
					);
					const curveTangent = curve.getTangentAt(t);
					cam.lookAt(cam.position.clone().add(curveTangent));

					target.set(...interpOrigin(t));
					this.controls.target = target;
					this.controls.update();
				})
				.start();
		};

		this.transitionCamTo = (newCoordsArray, resetControlsTarget = true, options = {}) => {
			const interpCamPos = d3InterpolateArray(
				this.camera.position.toArray(),
				newCoordsArray,
			);

			let interpOrigin;
			if (resetControlsTarget) {
				interpOrigin = d3InterpolateArray(
					this.controls.target.toArray(),
					[0, 0, 0],
				);
			}

			let interpRotation;
			if (this.material) {
				const twoPi = 2 * Math.PI;
				// const rotationFrom = this.material.uniforms.rotation.value;
				const rotationFrom = this.rotationGroup.rotation.y;
				const rotationTo = Math.round(rotationFrom / twoPi) * twoPi;
				interpRotation = d3Interpolate(rotationFrom, rotationTo);
			}

			let t = 0;
			const target = new THREE.Vector3();

			const animate = () => {
				t = Math.min(t + 0.035, 1.0);
				const easeT = d3EaseQuadOut(t);

				const camPos = interpCamPos(easeT);
				this.camera.position.x = camPos[0];
				this.camera.position.y = camPos[1];
				this.camera.position.z = camPos[2];

				if (interpRotation) {
					// this.material.uniforms.rotation.value = interpRotation(easeT);
					this.rotationGroup.rotation.y = interpRotation(easeT);
				}

				if (this.lineMaterial && options.fadeMap) {
					this.lineMaterial.opacity = easeT ** 20;
				}

				if (resetControlsTarget) {
					target.set(...interpOrigin(easeT));
					this.controls.target = target;
				}

				this.controls.update();

				if (t < 1) {
					requestAnimationFrame(animate);
				}
			};
			animate();
		};

		this.updateSimulation = (
			categoriesFilter,
			metaNodesFilter,
			showEdges = false,
			connectNodesToCenter = true,
			simOptions = {},
			selectedTagId = null,
			searchResults = null,
		) => {
			const { nodes, links, shouldConnectToCenter } = prepData(
				searchResults || window.allDataItems,
				categoriesFilter,
				metaNodesFilter,
				connectNodesToCenter,
				selectedTagId,
			);

			this.sim = makeSimulation(
				nodes,
				links,
				this.ticked,
				simOptions,
			);

			state.nodes = nodes;
			// TODO: filtering should probably be done in `prepData`
			state.links = links.filter(
				(link) => {
					const linksToCenter = isCenterNode(link.target) || isOriginNode(link.target);
					if (!shouldConnectToCenter) {
						if (!displayCenterEdges && linksToCenter) {
							return false;
						}
					}
					return true;
				},
			);

			if (showEdges) {
				// create lines
				const positions = [];
				// const indices = [];
				state.links.forEach((link) => {
					const verts = getEdgeVertices(link);
					const verts1 = verts[0].toArray();
					const verts2 = verts[1].toArray();
					/* eslint-disable fp/no-mutating-methods */
					positions.push(
						...verts1,
						...verts2,
					);
					/* eslint-enable fp/no-mutating-methods */
				});

				const geo = new THREE.BufferGeometry();
				// geo.setIndex(indices);
				geo.addAttribute(
					'position',
					new THREE.Float32BufferAttribute(positions, 3),
				);
				geo.attributes.position.dynamic = true;
				this.removeLines();
				this.lineSegments = new THREE.LineSegments(geo, edgeLineMaterial);
				this.lineSegments.frustumCulled = false;
				this.lineSegments.visible = true;
				this.rotationGroup.add(this.lineSegments);
			}
			this.updateArrays();
		};

		this.updateMap = async (categoriesFilter, metaNodesFilter) => {
			const scale = 0.4;
			const mercator = d3GeoMercator();

			let { nodes } = prepData(
				window.allDataItems,
				categoriesFilter,
				metaNodesFilter,
			);
			nodes = R.pipe(
				R.filter(
					(n) => {
						return (n.countries || []).length > 0;
					},
				),

				// activities can have multiple countries!
				// that's why we have to duplicate nodes
				R.map((node) => {
					return node.countries.map(
						(countryCode) => R.assoc('countries', [countryCode], node),
					);
				}),
				R.unnest,
			)(nodes);

			// not loaded yet
			if (!countryOutlinesLoaded) {
				countryOutlinesLoaded = true;
				const mapData = (
					await import(
						/* webpackChunkName: "map-data" */
						'../../data/map-data.json' // eslint-disable-line comma-dangle
					)
				).default;

				R.values(countries).forEach((country) => {
					const centroid = [country.centroid].map(mercator)[0];
					country.centroidMercator = [
						centroid[0] * scale,
						-centroid[1] * scale, // flip
					];
				});

				let minX, minY, maxX, maxY; /* eslint-disable-line */
				const projectCoords = (coords) => {
					const projected = coords.map(mercator);
					return projected.map((coord) => {
						const c = [
							coord[0] * scale,
							-coord[1] * scale, // flip
						];
						if (minX === undefined || c[0] < minX) { minX = c[0]; }
						if (maxX === undefined || c[0] > maxX) { maxX = c[0]; }
						if (minY === undefined || c[0] < minY) { minY = c[1]; }
						if (maxY === undefined || c[1] > maxY) { maxY = c[1]; }
						return c;
					});
				};

				this.lineMaterial = new THREE.LineBasicMaterial({
					color: 'black',
					linewidth: 1, // TODO: does not work in chrome and ff
					opacity: 1.0,
				});
				this.countriesGroup = new THREE.Group();

				mapData.features
					.forEach((feature) => {
						// treat everything like a MultiPolygon:
						if (feature.geometry.type === 'Polygon') {
							feature.geometry.coordinates = [feature.geometry.coordinates];
						}

						feature.geometry.coordinates = feature.geometry.coordinates
							.map((coordsList) => coordsList.map(projectCoords));

						R.pipe(
							R.unnest,
							R.forEach((coords) => {
								const points = coords.map(
									(coord) => new THREE.Vector3(...coord, 0),
								);
								/* eslint-disable-next-line no-shadow */
								const geometry = new THREE.Geometry();
								geometry.vertices = points;
								geometry.verticesNeedUpdate = true;
								const line = new THREE.Line(geometry, this.lineMaterial);
								this.countriesGroup.add(line);
								line.frustumCulled = false;
							}),
						)(feature.geometry.coordinates);
					});

				const w = maxX - minX;
				const h = maxY - minY;
				this.countriesGroup.position.x = -0.53 * w;
				this.countriesGroup.position.y = 0.25 * h;
				this.scene.add(this.countriesGroup);
			}
			this.countriesGroup.visible = true;

			const countryNodes = {};
			let count = 0;
			nodes.forEach((node) => {
				node.countries.forEach((countryCode) => {
					const country = countries[countryCode];
					if (!country) { return; }
					count += 1;
					if (!countryNodes[countryCode]) {
						countryNodes[countryCode] = [];
					}
					countryNodes[countryCode] = [
						...countryNodes[countryCode],
						node,
					];
				});
			});

			let totalIndex = 0;
			state.nodes = [];
			const translateArrayFrom = new Float32Array(count * 3);
			const translateArrayTo = new Float32Array(count * 3);
			const setCoords = (i3, node, pos) => {
				translateArrayFrom[i3 + 0] = node.x || 0;
				translateArrayFrom[i3 + 1] = node.y || 0;
				translateArrayFrom[i3 + 2] = node.z || 0;
				translateArrayTo[i3 + 0] = (this.countriesGroup.position.x + pos[0]) / SCALE;
				translateArrayTo[i3 + 1] = (this.countriesGroup.position.y + pos[1]) / SCALE;
				translateArrayTo[i3 + 2] = 0;
			};
			R.pipe(
				R.toPairs,
				R.forEach(
					/* eslint-disable-next-line no-shadow */
					([countryCode, nodes]) => {
						nodes.forEach((node, i) => {
							node.isVisible = true;
							const i3 = totalIndex * 3;
							const pos = [
								countries[countryCode].centroidMercator[0],
								countries[countryCode].centroidMercator[1] + (i * 1.4),
							];
							setCoords(i3, node, pos);
							state.nodes = [...state.nodes, node];
							totalIndex += 1;
						});
					},
				),
			)(countryNodes);

			this.updateArrays();

			// TODO: DRY
			const interpNodes = d3InterpolateArray(
				translateArrayFrom,
				translateArrayTo,
			);
			let t = 0;
			const animate = () => {
				t = Math.min(t + 0.035, 1.0);
				const easeT = d3EaseQuadOut(t);

				/* eslint-disable-next-line no-shadow */
				const translateArray = interpNodes(easeT);
				state.nodes.forEach((node, i) => {
					const i3 = i * 3;
					node.x = translateArray[i3 + 0];
					node.y = translateArray[i3 + 1];
					node.z = translateArray[i3 + 2];
				});
				this.ticked();

				if (t < 1) {
					requestAnimationFrame(animate);
				}
			};
			animate();
		};

		this.updateChronology = (categoriesFilter, metaNodesFilter) => {
			const presentYear = (new Date()).getFullYear();

			const { nodes } = prepData(
				window.allDataItems,
				categoriesFilter,
				metaNodesFilter,
			);
			const sortedNodes = R.pipe(
				R.filter((n) => !n.meta),
				R.filter((n) => n.years.length > 0),

				// activities can have multiple years!
				// that's why we have to duplicate nodes
				R.map((node) => {
					return node.years.map(
						(year) => R.assoc('years', [year], node),
					);
				}),
				R.unnest,
			)(nodes);

			const years = R.pipe(
				R.map(R.path(['years', 0])),
				R.uniq,
			)(sortedNodes);

			const yearLabelNodes = R.filter(
				(n) => (n.meta && years.includes(n.id)),
				nodes,
			);

			const stepSize = 7;
			const rowLen = 15;
			const count = sortedNodes.length + yearLabelNodes.length;
			const translateArrayFrom = new Float32Array(count * 3);
			const translateArrayTo = new Float32Array(count * 3);

			const groupedNodes = R.groupBy(R.path(['years', 0]), sortedNodes);
			const yearNodePairs = R.pipe(
				R.toPairs,
				R.sortBy(R.head),
			)(groupedNodes);

			const rowOffset = -(rowLen / 2) + 1;
			let colOffset = 0;
			let totalIndex = 0;

			// const aspect = window.innerWidth / window.innerHeight;
			const xOffset = 0 /*aspect * -65*/;

			const setCoords = (i3, node, rowIndex, colIndex, isLabel = false) => {
				translateArrayFrom[i3 + 0] = node.x || 0;
				translateArrayFrom[i3 + 1] = node.y || 0;
				translateArrayFrom[i3 + 2] = node.z || 0;

				translateArrayTo[i3 + 0] = ((colIndex + colOffset) * stepSize) + xOffset;
				translateArrayTo[i3 + 1] = -((rowIndex + rowOffset) * stepSize);
				translateArrayTo[i3 + 2] = 0;

				if (isLabel) {
					// translateArrayTo[i3 + 0] -= 2.25;
					translateArrayTo[i3 + 0] -= 1.75;
					translateArrayTo[i3 + 1] += 1.1;
				}
			};

			state.nodes = [];
			yearNodePairs.forEach(
				([year, yearNodes]) => {
					const futureYearOffset = 0 /*(year > presentYear) ? 1 : 0*/;

					const yearLabelNode = R.find(
						(node) => `${node.id}` === year,
						yearLabelNodes,
					);
					state.nodes = [...state.nodes, yearLabelNode];
					yearLabelNode.active = true; // TODO: needed?

					/* eslint-disable no-shadow */
					const i3 = totalIndex * 3;
					totalIndex += 1;
					const [rowIndex, colIndex] = [-1, 0];
					/* eslint-enable no-shadow */

					setCoords(
						i3,
						yearLabelNode,
						rowIndex,
						colIndex - futureYearOffset,
					);
					translateArrayTo[i3 + 0] -= 0.5 * stepSize;

					let numCols = 0;
					let maxColIndex = 0;
					yearNodes.forEach((node, ii) => {
						state.nodes = [...state.nodes, node];
						/* eslint-disable no-shadow */
						const { rowIndex, colIndex } = getGridPositionHorizontal(ii, rowLen);
						const i3 = totalIndex * 3;
						const finalColIndex = colIndex - futureYearOffset;
						totalIndex += 1;
						/* eslint-enable no-shadow */
						setCoords(
							i3,
							node,
							rowIndex,
							finalColIndex,
							true,
						);
						if (parseInt(year, 10) <= presentYear) {
							maxColIndex = Math.max(
								finalColIndex,
								maxColIndex,
							);
						}
						numCols = colIndex + 1;
					});

					colOffset += numCols + 1;
				},
			);

			const colShift = (colOffset - 1) * 0.5;
			state.nodes.forEach((node, i) => {
				const i3 = i * 3;
				translateArrayTo[i3] -= colShift * stepSize;
			});

			this.updateArrays();

			const interpNodes = d3InterpolateArray(
				translateArrayFrom,
				translateArrayTo,
			);
			let t = 0;
			const animate = () => {
				t = Math.min(t + 0.035, 1.0);
				const easeT = d3EaseQuadOut(t);

				/* eslint-disable-next-line no-shadow */
				const translateArray = interpNodes(easeT);
				state.nodes.forEach((node, i) => {
					const i3 = i * 3;
					node.x = translateArray[i3 + 0];
					node.y = translateArray[i3 + 1];
					node.z = translateArray[i3 + 2];
				});
				this.ticked();

				if (t < 1) {
					requestAnimationFrame(animate);
				}
			};
			animate();
		};

		this.hideAllLabels = () => {
			R.values(this.idToTextObj).forEach((textObj) => {
				textObj._childObject.visible = false;
			});
		};

		this.removeLines = () => {
			if (this.lineSegments) {
				this.rotationGroup.remove(this.lineSegments);
			}
		};

		this.layoutReset = () => {
			document.querySelector('#topic-reset').style.display = 'none';

			// stop whatever is currently happening
			if (this.sim) {
				this.sim.stop();
			}

			// hide all labels
			this.hideAllLabels();
			if (this.countriesGroup) {
				this.countriesGroup.visible = false;
			}

			// reset controls
			if (this.controls) {
				this.controls.enabled = true;
				this.controls.enableRotate = true;
				this.props.dispatch(
					setEnabledInteractions({
						zoomEnabled: true,
						panningEnabled: true,
						rotationEnabled: true,
					}),
				);
			}

			// remove edges
			this.removeLines();
		};

		let prevPathname = null;
		this.initLayout = (props, force = false) => {
			if (props.pathname === paths.SEARCH && window.query !== window.prevQuery) {
				// eslint-disable-next-line no-param-reassign
				force = true;
				window.prevQuery = window.query;
			}
			// only react, when s.th. actually changed
			if (!force && props.pathname === prevPathname) {
				return;
			}
			if (!prevPathname && (props.pathname === paths.INDEX)) {
				state.rotate = true;
			} else {
				state.rotate = false;
			}
			const fadeMap = !!prevPathname;
			prevPathname = props.pathname;
			state.pathname = props.pathname;

			this.layoutReset();

			// reset uniforms
			state.useTexture = 1;
			state.useDotColor = 0;
			state.showOutline = 1;

			// reset filters
			let categoriesFilter = [];
			let metaNodesFilter = [];
			const slug = getLastPathPart(props.pathname);

			if (props.pathname === paths.TOPICS) {
				this.transitionCamTo([
					camInitialPos[0],
					camInitialPos[1],
					camInitialPos[2] * 2.5,
				]);
				state.showMetaNodeLabels = true;
				metaNodesFilter = ['tags'];
				const showEdges = true;
				const connectNodesToCenter = true;
				return this.updateSimulation(
					categoriesFilter,
					metaNodesFilter,
					showEdges,
					connectNodesToCenter,
					topicsSimOptions,
				);
			} else if (props.pathname === paths.CHRONOLOGY) {
				this.transitionCamTo([
					camInitialPos[0],
					camInitialPos[1],
					camInitialPos[2] * 0.7,
				]);
				state.showMetaNodeLabels = true;
				metaNodesFilter = ['years'];
				this.controls.enableRotate = false; // disable rotation
				this.props.dispatch(
					setEnabledInteractions({
						rotationEnabled: false,
					}),
				);
				return this.updateChronology(categoriesFilter, metaNodesFilter);
			} else if (props.pathname === paths.LOCATIONS) {
				this.transitionCamTo(
					[
						camInitialPos[0],
						camInitialPos[1],
						camInitialPos[2] / 0.8,
					],
					true,
					{ fadeMap },
				);
				this.controls.enableRotate = false; // disable rotation
				this.props.dispatch(
					setEnabledInteractions({
						rotationEnabled: false,
					}),
				);
				return this.updateMap(categoriesFilter, metaNodesFilter);
			} else if (props.pathname === paths.INDEX) {
				state.useTexture = 0;
				state.useDotColor = 0;
				state.showOutline = 0;
				this.transitionCamTo([
					camInitialPos[0],
					camInitialPos[1],
					camInitialPos[2] * 1.7,
				]);
				state.showMetaNodeLabels = true;
				metaNodesFilter = ['years', 'categories'];
				categoriesFilter = [];
				const showEdges = true;
				const connectNodesToCenter = true;
				return this.updateSimulation(categoriesFilter, metaNodesFilter, showEdges, connectNodesToCenter);
			} else if (props.pathname === paths.SEARCH) {
				const results = window.search.search(window.query);
				document.querySelector('#topic-reset').style.display = 'block';
				state.showMetaNodeLabels = true;
				this.updateSimulation(
					[], // categoriesFilter
					[], // metaNodesFilter
					true, // showEdges
					false, // connectNodesToCenter
					{}, // simOptions
					undefined, // selectedTagId
					results,
				);
			} else { /* eslint-disable-line no-else-return */
				state.rotate = true;
				const f = {
					[paths.RESEARCH_PROJECTS]: 1,
					[paths.MASTER_LECTURES]: 0.7,
					[paths.RESEARCH_INITIATIVES]: 1,
					[paths.AM_VR]: 1.2,
					[paths.AIR]: 0.7,
					[paths.PUBLIC_EXPOSURE]: 1.7,
					[paths.IMPACT]: 1.8,
				}[state.pathname];
				this.transitionCamTo([
					camInitialPos[0],
					camInitialPos[1],
					camInitialPos[2] * f,
				]);
				state.showMetaNodeLabels = true;
				metaNodesFilter = ['sub-categories'];
				categoriesFilter = [slug];
				const showEdges = true;
				const connectNodesToCenter = true;
				return this.updateSimulation(categoriesFilter, metaNodesFilter, showEdges, connectNodesToCenter);
			}
		};

		this.material = new THREE.RawShaderMaterial({
			uniforms: {
				map: { value: tex },
				useTexture: { value: withTextures ? 1.0 : 0.0 },
				// time: { value: 0.0 },
				// rotation: { value: 0.0 },
				usePicking: { value: 0.0 },
				useDotColor: { value: 0.0 },
				showOutline: { value: 1.0 },
				texAtlasTileSize: { value: texAtlasTileSize },
			},
			vertexShader: shader.vertex,
			fragmentShader: shader.fragment,
			depthTest: true,
			depthWrite: true,
		});
		this.mesh = new THREE.Mesh(geometry, this.material);
		this.mesh.scale.set(SCALE, SCALE, SCALE);
		this.mesh.frustumCulled = false; // fixes everything disappearing, when panning

		this.rotationGroup.add(this.mesh);

		this.initLayout(this.props);

		this.pickingTexture = new THREE.WebGLRenderTarget(width, height);
		this.pickingTexture.texture.minFilter = THREE.LinearFilter;

		this.tooltipElem = document.querySelector('#tooltip');
		let nodeClickHandler;
		let mouseIsDown = false;
		this.handleMouseDown = (event) => {
			mouseIsDown = true;
		};
		this.handleMouseUp = (event) => {
			mouseIsDown = false;
		};
		window.addEventListener('mousedown', this.handleMouseDown);
		window.addEventListener('mouseup', this.handleMouseUp);
		const mouseMoveHandler = (event) => {
			if (mouseIsDown) { return; }
			if (state.contentIsOpen) { return; }

			this.material.uniforms.usePicking.value = 1;
			this.material.uniforms.showOutline.value = 0;
			R.values(this.idToTextObj).forEach((textObj) => {
				textObj._childObject.children[0].material.uniforms.usePicking.value = 1.0;
			});
			this.renderer.setRenderTarget(this.pickingTexture);
			this.renderer.render(this.scene, this.camera);
			const pixelBuffer = new Uint8Array(4);
			this.renderer.readRenderTargetPixels(
				this.pickingTexture,
				event.clientX,
				this.pickingTexture.height - event.clientY,
				1,
				1,
				pixelBuffer,
			);

			/* eslint-disable no-bitwise, indent */
			const index = (
				pixelBuffer[0] << 16)
				| (pixelBuffer[1] << 8)
				| (pixelBuffer[2]
			) - 1;
			/* eslint-enable no-bitwise, indent */

			// let needsUpdate = false;
			// if (window.prevPickingIndex !== index) {
			// 	state.nodes.forEach((node) => {
			// 		node.showImage = 0;
			// 	});
			// 	needsUpdate = true;
			// }

			const sum = R.sum(pixelBuffer) / 255;
			const isNonBackground = (rendererSettings.alpha)
				? sum > 0.0 // with transparency on, the bg is [0, 0, 0, 0]
				: sum < 4.0;
			if (isNonBackground) {
				if (window.prevPickingIndex !== index) {
					const pcKey = (index + 1).toString(16);
					const node = window.pickingColorLookUp[pcKey];
					if (!node) {
						// it's a label then!

						if (state.pathname !== paths.TOPICS) {
							return;
						}

						if (!pickingColorLabelLookUp[pcKey]) {
							return;
						}

						this.renderer.domElement.style.cursor = 'pointer';
						if (nodeClickHandler) {
							this.renderer.domElement.removeEventListener('click', nodeClickHandler);
						}

						const labelNode = pickingColorLabelLookUp[pcKey]._childObject.userData.node;
						nodeClickHandler = () => {
							const pos = [
								labelNode.x * SCALE,
								labelNode.y * SCALE,
								labelNode.z * SCALE,
							];
							this.flyToCoords(pos);
						};
						if (labelNode.isInitialLetter) {
							this.renderer.domElement.addEventListener('click', nodeClickHandler);
							return;
						}

						/* eslint-disable-next-line no-shadow */
						nodeClickHandler = (event) => {
							event.stopPropagation();

							document.querySelector('#topic-reset').style.display = 'block';

							// console.log(/*labelNode.label ||*/ labelNode.id);
							this.hideAllLabels();
							this.removeLines();
							this.transitionCamTo([
								camInitialPos[0],
								camInitialPos[1],
								camInitialPos[2] * 0.7,
							]);
							state.showMetaNodeLabels = true;
							const categoriesFilter = [];
							const metaNodesFilter = ['tags'];
							const showEdges = true;
							const connectNodesToCenter = true;
							this.controls.enableRotate = true;
							this.props.dispatch(
								setEnabledInteractions({
									rotationEnabled: true,
								}),
							);
							return this.updateSimulation(
								categoriesFilter,
								metaNodesFilter,
								showEdges,
								connectNodesToCenter,
								/*{
									...topicsSimOptions,
									dimenstions: 3,
								},*/
								undefined,
								labelNode.id,
							);
						};
						this.renderer.domElement.addEventListener('click', nodeClickHandler);
						return;
					}

					const color = getNodeColor(node);
					const bgColor = getNodeBgColor(node);
					let previewImg = '';
					if (node.previewFile) {
						previewImg = `<img src="${process.env.URL_PREFIX}preview-images/${node.previewFile}" style="display: block; max-width: 100%; margin-top: 0.5em;" />`;
					}
					this.tooltipElem.innerHTML = `
						<div style="font-weight: bold;">${getTitle(node)}</div>
						${previewImg}
					`;
					this.tooltipElem.style.display = 'inline-block';
					const { height: h } = this.tooltipElem.getBoundingClientRect();
					this.tooltipElem.style.left = `${event.clientX + 5}px`;
					this.tooltipElem.style.top = `${event.clientY - (h / 2) - 5}px`;
					this.tooltipElem.style.visibility = 'visible';
					this.tooltipElem.style.background = color;
					this.renderer.domElement.style.cursor = 'pointer';
					if (nodeClickHandler) {
						this.renderer.domElement.removeEventListener('click', nodeClickHandler);
					}
					/* eslint-disable-next-line no-shadow */
					nodeClickHandler = (event) => {
						event.stopPropagation();
						this.tooltipElem.style.visibility = 'hidden';
						// fixes data being undefined in production
						const activityData = { ...node };
						this.props.dispatch(
							setContent({
								content: <ActivityContent activityData={activityData} />,
								color: bgColor,
							}),
						);
						this.renderer.domElement.removeEventListener('click', nodeClickHandler);
					};
					this.renderer.domElement.addEventListener('click', nodeClickHandler);
				}
				window.prevPickingIndex = index;
			} else {
				this.tooltipElem.style.display = 'none';
				this.renderer.domElement.style.cursor = '';
				this.renderer.domElement.removeEventListener('click', nodeClickHandler);
				window.prevPickingIndex = -1;
			}

			// if (needsUpdate) {
			// 	this.updateArrays();
			// }
		};
		const throttledMouseMoveHandler = throttle(mouseMoveHandler, 1000 / 20); // max. 20 fps
		this.renderer.domElement.addEventListener('mousemove', throttledMouseMoveHandler);

		this.renderer.domElement.style.position = 'absolute';
		this.renderer.domElement.style.top = '0';
		this.renderer.domElement.style.left = '0';

		if (withBlurryBg) {
			this.blurCanvasDownScale = (isFF) ? 4 : 8;
			const blurCanvas = document.createElement('canvas');
			blurCanvas.style.width = `${width}px`;
			blurCanvas.style.height = `${height}px`;
			// https://github.com/flozz/StackBlur/issues/19#issuecomment-403256711
			blurCanvas.width = Math.floor(width / this.blurCanvasDownScale);
			blurCanvas.height = Math.floor(height / this.blurCanvasDownScale);
			blurCanvas.style.position = 'absolute';
			blurCanvas.style.top = '0';
			blurCanvas.style.left = '0';
			blurCanvas.style.pointerEvents = 'none';
			this.mount.prepend(blurCanvas);
			this.ctx = blurCanvas.getContext('2d');

			if (!isFF) {
				// looks like shit:
				// this.ctx.filter = 'blur(15px)';

				// slow af in ff:
				// blurCanvas.style.filter = 'blur(30px) saturate(200%)';
				blurCanvas.style.filter = 'blur(30px) brightness(100%)';
				// TODO: make blur radius relative to resolution
			} else {
				blurCanvas.style.opacity = '0.6';
			}
		}

		this.start();
	}

	componentWillUnmount() {
		this.stop();
		this.controls.dispose();
		window.removeEventListener('mousedown', this.handleMouseDown);
		window.removeEventListener('mouseup', this.handleMouseUp);
		this.mount.removeChild(this.renderer.domElement);
	}

	/* eslint-disable-next-line camelcase */
	UNSAFE_componentWillReceiveProps(nextProps) {
		state.contentIsOpen = nextProps.contentIsOpen;
		state.history = nextProps.history;
		this.initLayout(nextProps);
	}

	render() {
		return <Fragment>
			<div
				className={css`
					width: 100%;
					height: 100%;
				`}
				ref={(ref) => { this.mount = ref; }}
			/>

			<div
				id="tooltip"
				className={css`
					position: fixed;
					pointer-events: none;
					${responsizeFontSize.smaller}
					width: 13rem;
					display: none;
					padding: 5px;
					border: solid 1px black;
					z-index: 99;
					color: black;
				`}
			/>

			<CloseButton
				id="topic-reset"
				extraStyles={css`
					position: fixed;
					top: ${margins.m};
					left: 50vw;
					transform: translateX(-50%);
					${responsizeFontSize.topMenu}
					display: none;
					padding: 5px;
					margin: 0;
					border: solid 1px black;
					cursor: pointer;
					${backdropBlur}
				`}
				onClick={(event) => {
					event.stopPropagation();
					document.querySelector('#topic-reset').style.display = 'none';
					if (state.pathname === paths.SEARCH) {
						// eslint-disable-next-line fp/no-mutating-methods
						state.history.push(window.returnToPath);
					} else {
						this.initLayout(this.props, true);
					}
				}}
			/>
		</Fragment>;
	}
}

ThreeCanvas.propTypes = {
	pathname: PropTypes.string,
	dispatch: PropTypes.func.isRequired,
	contentIsOpen: PropTypes.bool.isRequired,
};

// ThreeCanvas.defaultProps = {
// };

export default ThreeCanvas;
