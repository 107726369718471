import { Link } from 'react-router-dom';

import { responsizeFontSize } from '../styles';


const menuItemStyle = css`
	text-decoration: none;
	color: black;
	${responsizeFontSize.topMenu}
	user-select: none;
`;

const MenuItem = ({ children, to, disabled, noLink, onClick }) => {
	const commonProps = {
		onClick: (disabled) ? undefined : onClick,
		draggable: false,
		className: cx(
			menuItemStyle,
			css`
				opacity: ${(disabled) ? 0.3 : 1};
				${(disabled) ? 'pointer-events: none;' : ''}
				cursor: pointer;
				display: inline-block;
			`,
		),
	};
	/* eslint-disable jsx-control-statements/jsx-use-if-tag */
	return (noLink)
		? <div {...commonProps}>
			{children}
		</div>
		: <Link {...commonProps} to={to}>
			{children}
		</Link>;
	/* eslint-enable jsx-control-statements/jsx-use-if-tag */
};

MenuItem.propTypes = {
	children: PropTypes.any.isRequired,
	to: PropTypes.any,
	disabled: PropTypes.bool,
	noLink: PropTypes.bool,
	onClick: PropTypes.func,
};

export default MenuItem;
