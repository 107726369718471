import R from 'ramda';
import * as THREE from 'three';

import texAtlas from '../tools/texture-atlas.json';

import { actionTypes } from './constants';
import { createSearch } from './search';


export const initialState = {
	hasSeenPrelaunch: false,
	dataLoaded: false,
	contentFrameContent: null,
	contentFrameColor: null,
	contentFrameIsIntro: false,
	seenIntroPathnames: [],
	enabledInteractions: {
		zoomEnabled: true,
		panningEnabled: true,
		rotationEnabled: true,
	},
};


export default (state = initialState, action) => {
	if (action.type === actionTypes.SET_CONTENTFRAME_CONTENT) {
		// const canvas = document.querySelector('#main-canvas');
		// canvas.style.opacity = (!action.content) ? '1' : '0';
		return {
			...state,
			contentFrameContent: action.content,
			contentFrameColor: action.color,
			contentFrameIsIntro: action.isIntro,
			seenIntroPathnames: (action.isIntro && !!action.pathname)
				? R.uniq([...state.seenIntroPathnames, action.pathname])
				: state.seenIntroPathnames,
		};
	}

	if (action.type === actionTypes.SET_ENABLED_INTERACTIONS) {
		return {
			...state,
			enabledInteractions: {
				...state.enabledInteractions,
				...action.enabledInteractions,
			},
		};
	}

	if (action.type === actionTypes.SET_HAS_SEEN_PRELAUNCH) {
		return {
			...state,
			hasSeenPrelaunch: action.hasSeenPrelaunch,
		};
	}

	if (action.type === actionTypes.DATA_LOADED) {
		window.dataByCategory = action.dataByCategory;
		const pickingColor = new THREE.Color();
		window.pickingColorLookUp = {};
		window.allDataItems = R.unnest(
			R.values(window.dataByCategory),
		)
			.map((item, i) => {
				const [colIndex, rowIndex] = texAtlas.nameToPosition[item.previewFile] || [-1, -1];
				// TODO: what to do about the missing ones?
				const it = {
					...item,
					pickingColor: pickingColor.setHex(i + 1).toArray(),
					uvOffset: [
						colIndex / texAtlas.rowLen,
						((texAtlas.rowLen - 1) - rowIndex) / texAtlas.rowLen,
					],
				};
				const pcKey = (i + 1).toString(16);
				window.pickingColorLookUp[pcKey] = it;
				return it;
			});
		window.allDataItems.forEach((item, i) => {
			item._id = i;
		});
		window.search = createSearch(window.allDataItems);
		return {
			...state,
			dataLoaded: true,
		};
	}

	return state;
};
