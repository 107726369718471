const { version, name } = require('../package.json');


export const appName = name;
export const appVersion = version;


export const actionTypes = {
	SET_CONTENTFRAME_CONTENT: 'SET_CONTENTFRAME_CONTENT',
	SET_ENABLED_INTERACTIONS: 'SET_ENABLED_INTERACTIONS',
	DATA_LOADED: 'DATA_LOADED',
	SET_HAS_SEEN_PRELAUNCH: 'SET_HAS_SEEN_PRELAUNCH',
};
