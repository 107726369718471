import { margins, breakPointL } from '../styles';

import PanControls from './PanControls';


const style = css`
	position: fixed;
	left: 0px;
	bottom: -1px;
	padding: ${margins.m};

	user-select: none;
	pointer-events: none;
	z-index: 99;
	font-size: 11px;
	display: inline-block;
	opacity: 1;
	max-width: 220px;

	@media (max-width: ${breakPointL}) {
		display: none;
	}

	table td {
		vertical-align: top;
	}
	table td:first-child {
		padding-right: 0.5em;
	}
`;


const disabledStyle = css`opacity: 0.3;`;


const InteractionLegend = (props) => {
	const {
		zoomEnabled,
		panningEnabled,
		rotationEnabled,
	} = props;

	return <div className={style}>
		<div
			className={css`
				position: fixed;
				right: ${margins.m};
				bottom: ${margins.m};
			`}
		>
			<PanControls />
		</div>

		<table cellSpacing="0" cellPadding="0">
			<tbody>
				<tr className={(rotationEnabled) ? '' : disabledStyle}>
					<td>rotate:</td>
					<td>drag left with mouse button</td>
				</tr>
				<tr className={(zoomEnabled) ? '' : disabledStyle}>
					<td>zoom:</td>
					<td>use mouse wheel</td>
				</tr>
				<tr className={(panningEnabled) ? '' : disabledStyle}>
					<td>pan:</td>
					<td>
						drag with right mouse button<br />
						or use arrow keys
					</td>
				</tr>
			</tbody>
		</table>
	</div>;
};

InteractionLegend.propTypes = {
	zoomEnabled: PropTypes.bool.isRequired,
	panningEnabled: PropTypes.bool.isRequired,
	rotationEnabled: PropTypes.bool.isRequired,
};

// InteractionLegend.defaultProps = {
// };

export default InteractionLegend;
