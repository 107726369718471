import R from 'ramda';
import { Helmet } from 'react-helmet';
import ImageGallery from 'react-image-gallery';

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved */
import imageGalleryCss from 'raw-loader!../../node_modules/react-image-gallery/styles/css/image-gallery-no-icon.css';

import {
	getNames,
	getTitle,
	getYears,
	getLocations,
	getDescription,
	getImages,
	getWebsite,
	getOtherInfoLink,
	getPartners,
	getOnlinePublication,
	getPdfPublication,
	getVideos,
} from '../data';
import { H1 } from '../styles';


const ActivityContent = (props) => {
	const { activityData } = props;

	const website = getWebsite(activityData);
	const otherInfoLink = getOtherInfoLink(activityData);
	const partners = getPartners(activityData);
	const onlinePublication = getOnlinePublication(activityData);
	const pdfPublication = getPdfPublication(activityData);
	const videos = getVideos(activityData);
	const descriptionLines = getDescription(activityData);
	const images = getImages(activityData).map((imgPath) => {
		const url = `${process.env.URL_PREFIX}activity-images/${imgPath}`;
		return {
			original: url,
			thumbnail: url,
		};
	});

	const publications = [];
	/* eslint-disable fp/no-mutating-methods */
	if (otherInfoLink) {
		publications.push({ url: otherInfoLink, label: 'Additional information' });
	}
	if (onlinePublication) {
		publications.push({ url: onlinePublication, label: 'Online publication' });
	}
	if (pdfPublication) {
		publications.push({ url: `${process.env.URL_PREFIX}pdfs/${pdfPublication}`, label: 'PDF publication' });
	}
	/* eslint-enable fp/no-mutating-methods */

	return <div>
		<Helmet>
			<style>{imageGalleryCss}</style>
		</Helmet>

		<div className={css`max-width: 90%; margin: 0;`}>
			<H1>
				{getTitle(activityData)}
			</H1>
		</div>

		<div>
			{getNames(activityData).join(', ')}
			<If condition={website}>
				{' — '}<a href={website} target="_blank" rel="noopener noreferrer">Website</a>
			</If>
			<br />
			{R.intersperse(
				' — ',
				[
					getLocations(activityData),
					getYears(activityData),
				]
					.filter((list) => list && list.length)
					.map((list) => list.join(', ')),
			)}
			<br />
			<If condition={partners}>
				Partners: {partners}<br />
			</If>

			{R.intersperse(
				' — ',
				publications
					.filter(R.prop('url'))
					.map(({ url, label }) => <a key={url} href={url} target="_blank" rel="noopener noreferrer">{label}</a>),
			)}
		</div>

		<If condition={videos.length}>
			{videos.map((vid) => {
				const html = { __html: vid.embed };
				/* eslint-disable indent */
				const style = css`
					margin-top: 1em;

					${(!vid.aspect)
						? ''
						: `
							position: relative;
							height: 0;
							overflow: hidden;
							max-width: 100%;
							padding-bottom: ${100 / vid.aspect}%;

							iframe {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height:100%;
							}
						`
					}
				`;
				/* eslint-enable indent */
				return <div
					dangerouslySetInnerHTML={html}
					className={style}
				/>;
			})}
		</If>

		<If condition={descriptionLines && descriptionLines.length}>
			<div
				className={css`
					margin-top: 1em;
					pre {
						white-space: pre-wrap;
						word-break: keep-all;
						margin: 0;
					}
				`}
			>
				<pre>{descriptionLines.join('\n')}</pre>
			</div>
		</If>

		<If condition={images && images.length}>
			<div className={css`margin-top: 1em;`}>
				<ImageGallery items={images} />
			</div>
		</If>
	</div>;
};

ActivityContent.propTypes = {
	activityData: PropTypes.object.isRequired,
};

// ActivityContent.defaultProps = {
// };

export default ActivityContent;
