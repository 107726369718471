import { H1, H2, H3 } from '../../styles';


const IntroIMVR = (props) => {
	return <Fragment>
		<H1>IMMERSIVE MEDIA / VRAcademy</H1>
		<p>In consequence of the growing importance of the reflection on Immersive Media across the professional fields, the Visual Effects and Immersive Media department of the Film Academy initiated the VRAcademy. The VRAcademy aims to further develop the XR (Extended Reality) research and curriculum of the Film Academy and disseminate the expertise across other schools within the AHK, but also to stimulate innovation and cross-fertilization between educational institutions and the professional/business community.</p>
	</Fragment>;
};

// IntroIMVR.propTypes = {
// };

// IntroIMVR.defaultProps = {
// };

export default IntroIMVR;
