import R from 'ramda';

import { appName, appVersion } from './constants';
import categories from './categories';


/* eslint-disable no-console */
export const logBanner = () => {
	console.log(`${appName} ${appVersion}`);
	console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
	console.log(`URL_PREFIX: ${process.env.URL_PREFIX}`);
};
/* eslint-enable no-console */


// export const getScreenCoords = (obj, camera, width, height) => {
// 	const widthHalf = 0.5 * width;
// 	const heightHalf = 0.5 * height;

// 	obj.updateMatrixWorld();

// 	const vector = new THREE.Vector3();
// 	vector.setFromMatrixPosition(obj.matrixWorld);
// 	vector.project(camera);

// 	return {
// 		x: (vector.x * widthHalf) + widthHalf,
// 		y: -(vector.y * heightHalf) + heightHalf,
// 	};
// };


export const getLastPathPart = (path) => {
	return R.last(path.split('/'));
};


export const getNodeCategory = (node) => {
	return R.find(
		(cat) => {
			const slug = R.last(cat.path.split('/'));
			return slug === R.head(node.categories);
		},
		categories,
	);
};


export const getNodeId = (it) => {
	const fields = [
		it.TITLE,
		it['Name (Author - Director - Artist)'],
	];
	return it.id || `${fields.join('--')}`;
};


export const getNodeColor = (node) => {
	const category = getNodeCategory(node);
	const color = R.propOr(null, 'color')(category);
	return color;
};
export const getNodeBgColor = (node) => {
	const category = getNodeCategory(node);
	const color = R.propOr(null, 'bgColor')(category);
	return color;
};


export const getGridPosition = (gridIndex, rowLen) => {
	const rowIndex = Math.floor(gridIndex / rowLen);
	const colIndex = gridIndex % rowLen;
	return { rowIndex, colIndex };
};
export const getGridPositionHorizontal = (gridIndex, rowLen) => {
	const colIndex = Math.floor(gridIndex / rowLen);
	const rowIndex = gridIndex % rowLen;
	return { rowIndex, colIndex };
};
