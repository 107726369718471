// import { useState } from 'react';

import { margins/*, responsizeFontSize*/, breakPointL } from '../styles';
import categories from '../categories';
import { setContent } from '../actionCreators';

import SecondaryMenuItem from './SecondaryMenuItem';
// import CloseButtonFilter from './CloseButtonFilter';


// const tooltipWidth = '30rem';


const style = css`
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: ${margins.m};
	user-select: none;
	z-index: 99;
	pointer-events: none;
`;


const ActivitiesFilterMenu = ({ pathname, dispatch, seenIntroPathnames }) => {
	// let anySelected = false;
	// const [tooltipElem, setTooltipElem] = useState(null);
	// const [tooltipOpen, setTooltipOpen] = useState(false);
	// const [tooltipCategory, setTooltipCategory] = useState(false);

	return <div className={style}>
		{/*<div
			className={css`
				font-size: 1rem;
				margin-bottom: 7px;
				opacity: ${(tooltipOpen) ? '0' : '1'};
				transition: opacity 0.2s;
				text-align: center;
				@media (max-width: ${breakPointL}) {
					display: none;
				}
			`}
		>
			Activities
		</div>*/}

		<div
			className={css`
				display: flex;
				justify-content: center;
				align-items: center;

				@media (max-width: ${breakPointL}) {
					margin-left: 50%;
					transform: translateX(-50%);
					width: 80vw;
					flex-wrap: wrap;
					> * {
						margin-top: 6px;
					}
				}

				> * {
					display: inline-block;
					pointer-events: all;
				}
			`}
			// onMouseEnter={() => { setTooltipOpen(true); }}
			// onMouseLeave={() => { setTooltipOpen(false); }}
		>
			{categories.map((cat) => {
				const isSelected = cat.path === pathname;
				// anySelected = anySelected || isSelected;

				return <SecondaryMenuItem
					key={cat.path}
					to={cat.path}
					isSelected={isSelected}
					color={cat.color}
					// onMouseEnter={(event) => {
					// 	const { x: x1, width: w1 } = event.target.getBoundingClientRect();
					// 	tooltipElem.style.left = `calc(${x1}px + 0.5 * (${w1}px - ${tooltipWidth}))`;
					// 	tooltipElem.style.bottom = '50px';
					// 	setTooltipCategory(cat);
					// 	setTooltipOpen(true);
					// }}
					// onMouseLeave={() => {
					// 	setTooltipOpen(false);
					// }}
					onClick={() => {
						// if (!seenIntroPathnames.includes(cat.path)) {
						// 	dispatch(
						// 		setContent({
						// 			content: cat.introText,
						// 			color: cat.bgColor,
						// 			isIntro: true,
						// 			pathname: cat.path,
						// 		}),
						// 	);
						// } else {
							dispatch(
								setContent({ content: null }),
							);
						// }
					}}
				>
					{cat.label}
				</SecondaryMenuItem>;
			})}
		</div>

		{/*<div
			id="menu-tooltip"
			ref={setTooltipElem}
			className={css`
				position: fixed;
				pointer-events: none;
				${responsizeFontSize.smaller}
				padding: 0.5em;
				width: ${tooltipWidth};
				display: ${(tooltipOpen) ? 'inline-block' : 'none'};
				background: ${tooltipCategory.color};
				color: white;
			`}
		>
			{tooltipCategory.introText}
		</div>*/}

		{/*<div
			className={css`
				width: 0;
				flex: 0;
				transform: translateY(2px) translateX(16px);
			`}
		>
			<CloseButtonFilter disabled={!anySelected} />
		</div>*/}
	</div>;
};

ActivitiesFilterMenu.propTypes = {
	dispatch: PropTypes.func.isRequired,
	pathname: PropTypes.string.isRequired,
	seenIntroPathnames: PropTypes.array.isRequired,
};

// ActivitiesFilterMenu.defaultProps = {
// };

export default ActivitiesFilterMenu;
