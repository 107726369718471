import { actionTypes } from './constants';


export const dataLoaded = (dataByCategory) => {
	return (dispatch, getState) => {
		dispatch({
			type: actionTypes.DATA_LOADED,
			dataByCategory,
		});
	};
};


export const setContent = ({ content, color, isIntro, pathname }) => {
	return (dispatch, getState) => {
		window.prevPickingIndex = -1;
		dispatch({
			type: actionTypes.SET_CONTENTFRAME_CONTENT,
			content,
			color,
			isIntro,
			pathname,
		});
	};
};


export const setEnabledInteractions = (enabledInteractions) => {
	return (dispatch, getState) => {
		dispatch({
			type: actionTypes.SET_ENABLED_INTERACTIONS,
			enabledInteractions,
		});
	};
};


export const setHasSeenPrelaunch = (hasSeenPrelaunch) => {
	return {
		type: actionTypes.SET_HAS_SEEN_PRELAUNCH,
		hasSeenPrelaunch,
	};
};
