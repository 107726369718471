import { H1, H2, H3 } from '../../styles';


const IntroMasterLectures = (props) => {
	return <Fragment>
		<H1>Master Lectures</H1>
		<p>Roughly once every three weeks, the Lectoraat in Film invites filmmakers, artists and researchers – both from the Netherlands and abroad – to give a lecture about their research-driven work and practice. They are encouraged to use the space of the lecture itself as a space of investigation, or a testing ground for their works in progress. The lectures are free and open, in order to create both an internal and external impact.</p>
	</Fragment>;
};

// IntroMasterLectures.propTypes = {
// };

// IntroMasterLectures.defaultProps = {
// };

export default IntroMasterLectures;
