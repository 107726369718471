import { H1, UL } from '../../styles';

import imgPresent from '../../../assets/map PRESENT lektoraat-1 copy.png';
import imgFuture from '../../../assets/map future lektoraat-1.png';


const FuturePlans = (props) => {
	return <Fragment>
		<H1>Lectoraat’s Future Plans</H1>

		<p>The inception of the lectoraat within the Master “Artistic Research in and through Cinema" programme resulted in the development of various activities that simultaneously aid and surpass the research objectives of the course itself. After a period of incubation, the lectoraat outgrew the programme and its current activities are aimed at establishing a support structure that will foster the elements of artistic research across the whole Academy and will set to influence the professional field.</p>

		<p>This future lectoraat is primarily focused on restructuring existing elements to assure their future development and convergence. While some of the solutions (research groups, fellows) will allow to construct more efficient, flexible frames for current practices, most of the activities and objectives will not change. They will be restructured in 4 different, but related, research labs or studios:</p>
		<UL>
			<li><u>Pedagogy & learning</u>: research into educational development of Artistic research in & through Cinema. How can the pedagogy of artistic research strengthen (film) education?</li>
			<li><u>Thinking through making</u>: particular research projects developed within the frame of the lectoraat. How can experimental research into new forms of storytelling transform the professional filmmaking practice?</li>
			<li><u>Exposure & public validation</u>: research into the elements and strategies of public outreach, and modes of exhibition. In the light of domination of the written form when it comes to publishing and disseminating knowledge, can artistic research in and through cinema develop genuine, appropriate audiovisual modes of presentation?</li>
			<li><u>The Bridge</u>: research into the sustainability of the mode of Artistic Research developed within the lectoraat, and its convergence with the professional field. How to create a bridge between research projects developed within the educational institution, market and professional field?</li>
		</UL>
		<p>Together, these 4 labs or studios create <b>a Hub for research in and through cinema practices</b>.</p>

		<p>Introducing ‘research fellowships’ (connected to one or more of the labs) and ‘research groups’ around shared themes, the hub allows improvement of coordination and flexibility of the lectoraat’s activities.</p>

		<div
			className={css`
				img {
					max-width: 100%;
					max-height: 90%;
				}
			`}
		>
			<img src={imgPresent} alt="present" /><br />
			<br />
			<img src={imgFuture} alt="future" />
		</div>
	</Fragment>;
};

// FuturePlans.propTypes = {
// };

// FuturePlans.defaultProps = {
// };

export default FuturePlans;
