import {
	margins,
	scrollbarStyle,
	responsizeFontSize,
	// defaultBgColor,
	breakPointML,
	breakPointM,
} from '../styles';

import bgGradient from '../../assets/gradient-with-alpha.png';


const style = css`
	cursor: default;
	z-index: 9999999;
	position: fixed;
	${responsizeFontSize.content}
	background-image: url("${bgGradient}");
	background-size: contain;
	border: solid 1px black;
	overflow-y: auto;
	overscroll-behavior: none;
	${scrollbarStyle}
`;

const ContentFrame = (props) => {
	const { children, closeButton, color, isIntro, isPrelaunch } = props;

	/* eslint-disable indent */
	const responsive = css`
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		width: calc(100vw - 2rem + 1px);
		height: calc(100vh - 2rem);
		${(isIntro)
			? `
				max-height: calc(100vh - 2rem);
				height: auto;
			`
			: ''
		}

		@media (min-width: ${breakPointM}) {
			${(!isIntro)
				? 'max-width: 70vw;'
				: `
					max-width: 600px;
					max-height: 70vh;
					height: auto;
					top: 104px;
					left: 1rem;
					transform: unset;
				`
			}
		}

		@media (min-width: ${breakPointML}) {
			${(!isIntro)
				? `
					max-width: calc(50vw - ${margins.xl});
					${(isPrelaunch)
						? `
							height: auto;
							max-height: 70vh;
						`
						: `
							height: 70vh;
						`
					}
				`
				: `
					max-width: 600px;
					height: auto;
					max-height: 70vh;
					top: 104px;
					left: 1rem;
				`
			}
		}
	`;

	const backgroundStyle = css`
		${(color)
			? `background: ${color};`
			: ''
		}
	`;
	/* eslint-enable indent */

	return <div
		onClick={(event) => { event.stopPropagation(); }}
		className={cx(
			style,
			backgroundStyle,
			responsive,
		)}
	>
		{closeButton}
		<div
			onClick={(event) => { event.stopPropagation(); }}
			className={cx(
				css`
					padding: ${margins.m};

					height: auto;
					overflow-x: hidden;

					> *:not(:first-of-type) {
						margin-top: 1rem;
					}
				`,
			)}
		>
			{children}
		</div>
	</div>;
};

ContentFrame.propTypes = {
	children: PropTypes.any.isRequired,
	closeButton: PropTypes.any,
	color: PropTypes.string,
	isIntro: PropTypes.bool,
	isPrelaunch: PropTypes.bool,
};

ContentFrame.defaultProps = {
};

export default ContentFrame;
