import { margins, responsizeFontSize } from '../styles';
import paths from '../paths';
import { setContent } from '../actionCreators';

import MenuItem from './MenuItem';
// import CloseButtonFilter from './CloseButtonFilter';


const style = css`
	position: fixed;
	top: 0;
	right: 0;
	text-align: right;
	z-index: 99;
	padding: ${margins.m};
	user-select: none;
	${responsizeFontSize.topMenu}
`;


const VisualizationModeMenu = ({ pathname, /*showCloseButton,*/ dispatch }) => {
	// const anySelected = [
	// 	paths.TOPICS,
	// 	paths.LOCATIONS,
	// 	paths.CHRONOLOGY,
	// ].includes(pathname);

	return <div className={style}>
		<div
			// className={css`
			// 	> div:not(:first-of-type):not(:last-of-type) {
			// 		margin-bottom: 10px;
			// 	}
			// `}
		>
			{/*<div className={css`margin-bottom: 1em;`}>
				<CloseButtonFilter disabled={!(anySelected || showCloseButton)} />
			</div>*/}

			<div className={css`margin-bottom: 1em;`}>
				<MenuItem
					noMargin
					to={paths.INDEX}
					disabled={paths.INDEX === pathname}
				>
					LECTORAAT FILM (NFA)
				</MenuItem>
			</div>

			<div>
				<MenuItem
					noMargin
					to={paths.TOPICS}
					disabled={paths.TOPICS === pathname}
					onClick={() => {
						dispatch(
							setContent({ content: null }),
						);
					}}
				>
					Topics
				</MenuItem>
			</div>
			<div>
				<MenuItem
					noMargin
					to={paths.LOCATIONS}
					disabled={paths.LOCATIONS === pathname}
					onClick={() => {
						dispatch(
							setContent({ content: null }),
						);
					}}
				>
					Locations
				</MenuItem>
			</div>
			<div>
				<MenuItem
					noMargin
					to={paths.CHRONOLOGY}
					disabled={paths.CHRONOLOGY === pathname}
					onClick={() => {
						dispatch(
							setContent({ content: null }),
						);
					}}
				>
					Chronology
				</MenuItem>
			</div>
		</div>
	</div>;
};

VisualizationModeMenu.propTypes = {
	showCloseButton: PropTypes.bool.isRequired,
	dispatch: PropTypes.func.isRequired,
	pathname: PropTypes.string.isRequired,
};

// VisualizationModeMenu.defaultProps = {
// };

export default VisualizationModeMenu;
