import styled from '@emotion/styled';


export const bgColor = 'white';
export const closeButtonBgColor = 'transparent';

export const defaultBgColor = 'rgba(255, 255, 255, 0.9)';

export const blueKlein = '#0033cc';

export const _backdropBlur = 'backdrop-filter: blur(10px)';
export const backdropBlur = css`
	@supports (${_backdropBlur}) {
		${_backdropBlur};
		background: rgba(255, 255, 255, 0.25);
	}
`;

export const margins = {
	s: '0.5rem',
	m: '1rem',
	l: '2rem',
	xl: '4rem',
};

export const fontSize = {
	smaller: '14px',
	content: '16px',
	contentMobile: '14px',
	topMenu: '18px',
	topMenuMobile: '14px',
	bottomMenu: '12px',
	bottomMenuMobile: '11px',
};

export const breakPointL = '1200px';
export const breakPointML = '900px';
export const breakPointM = '600px';

export const responsizeFontSize = {
	smaller: css`
		font-size: ${fontSize.smaller};
	`,
	content: css`
		font-size: ${fontSize.content};
		@media (max-width: ${breakPointL}) {
			font-size: ${fontSize.contentMobile};
		}
	`,
	topMenu: css`
		font-size: ${fontSize.topMenu};
		@media (max-width: ${breakPointM}) {
			font-size: ${fontSize.topMenuMobile};
		}
	`,
	bottomMenu: css`
		font-size: ${fontSize.bottomMenu};
		@media (max-width: ${breakPointL}) {
			font-size: ${fontSize.bottomMenuMobile};
		}
	`,
};

export const scrollbarWidth = '8px';
export const scrollbarStyle = css`
	::-webkit-scrollbar {
		width: ${scrollbarWidth};
	}

	::-webkit-scrollbar-track {
		background: transparent;
	}

	::-webkit-scrollbar-thumb {
		background: black;
	}
`;

export const H1 = styled.h1`
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1rem;
	margin: 0;
	margin-bottom: 1rem;
`;

export const H2 = styled.h2`
	font-weight: normal;
	text-transform: uppercase;
	font-size: 1rem;
	margin: 0;
	margin-bottom: 1rem;
`;

export const H3 = styled.h3`
	font-weight: normal;
	font-size: 1rem;
	margin: 0;
	text-decoration: underline;
`;

export const UL = styled.ul`
	margin: 0;
	padding: 0;
	padding-left: 1.1em;
	margin-bottom: 1rem;
	list-style-type: circle;
`;
