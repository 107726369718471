import tinycolor from 'tinycolor2';

import paths from './paths';

import IntroResearchProjects from './components/content/IntroResearchProjects';
import IntroResearchInitiatives from './components/content/IntroResearchInitiatives';
import IntroIMVR from './components/content/IntroIMVR';
import IntroPublicExposure from './components/content/IntroPublicExposure';
import IntroMasterLectures from './components/content/IntroMasterLectures';
import IntroAIR from './components/content/IntroAIR';
import IntroImpactQuality from './components/content/IntroImpactQuality';


const alpha = 0.9;

const colors = [
	/*researchProjects*/	'#77c7c7', // turquoise
	/*masterLectures*/		'#4f8644', // green
	/*researchInitiatives*/	'#a22022', // red
	/*amvr*/				'#be3c6f', // pink
	/*air*/					'#ce7d27', // orange
	/*publicExposure*/		'#157aaa', // blue
	/*impact*/				'#e4e57f', // yellow
];

const [
	researchProjects,
	masterLectures,
	researchInitiatives,
	amvr,
	air,
	publicExposure,
	impact,
] = colors;

const categories = [
	{
		label: 'research projects',
		introText: <IntroResearchProjects />,
		path: paths.RESEARCH_PROJECTS,
		color: researchProjects,
		bgColor: tinycolor(researchProjects)
			.setAlpha(alpha)
			.toRgbString(),
	},
	{
		label: 'master lectures',
		introText: <IntroMasterLectures />,
		path: paths.MASTER_LECTURES,
		color: masterLectures,
		bgColor: tinycolor(masterLectures)
			.setAlpha(alpha)
			.toRgbString(),
	},
	{
		label: 'research initiatives',
		introText: <IntroResearchInitiatives />,
		path: paths.RESEARCH_INITIATIVES,
		color: researchInitiatives,
		bgColor: tinycolor(researchInitiatives)
			.setAlpha(alpha)
			.toRgbString(),
	},
	{
		label: <Fragment>im&thinsp;/&thinsp;vra</Fragment>,
		introText: <IntroIMVR />,
		path: paths.AM_VR,
		color: amvr,
		bgColor: tinycolor(amvr)
			.setAlpha(alpha)
			.toRgbString(),
	},
	{
		label: 'air',
		introText: <IntroAIR />,
		path: paths.AIR,
		color: air,
		bgColor: tinycolor(air)
			.setAlpha(alpha)
			.toRgbString(),
	},
	{
		label: 'public exposure',
		introText: <IntroPublicExposure />,
		path: paths.PUBLIC_EXPOSURE,
		color: publicExposure,
		bgColor: tinycolor(publicExposure)
			.setAlpha(alpha)
			.toRgbString(),
	},
	{
		label: <Fragment>impact&thinsp;/&thinsp;quality</Fragment>,
		introText: <IntroImpactQuality />,
		path: paths.IMPACT,
		color: impact,
		bgColor: tinycolor(impact)
			.setAlpha(alpha)
			.toRgbString(),
	},
];
export default categories;
