import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';

import { bgColor, closeButtonBgColor } from '../styles';
import { setContent, setHasSeenPrelaunch } from '../actionCreators';

import ThreeCanvas from './ThreeCanvas';
import MainMenu from './MainMenu';
import ActivitiesFilterMenu from './ActivitiesFilterMenu';
import VisualizationModeMenu from './VisualizationModeMenu';
import InteractionLegend from './InteractionLegend';
import ContentFrame from './ContentFrame';
import CloseButton from './CloseButton';
import PrelaunchContent from './content/PrelaunchContent';
// import RouteNotFound from './RouteNotFound';


const closeButtonStyle = css`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 9;
	> a {
		text-decoration: none;
		color: black;
	}
`;


// let locationListening = false;


const App = (props) => {
	const {
		dispatch,
		contentFrameContent,
		contentFrameColor,
		contentFrameIsIntro,
		seenIntroPathnames,
		enabledInteractions,
		dataLoaded,
		hasSeenPrelaunch,
	} = props;

	const closeButton = <div className={closeButtonStyle}>
		<CloseButton
			extraStyles={css`
				background: ${closeButtonBgColor};
			`}
			onClick={() => {
				dispatch(
					setContent({ content: null, color: null }),
				);
				dispatch(
					setHasSeenPrelaunch(true),
				);
			}}
		/>
	</div>;

	if (!hasSeenPrelaunch) {
		return <div
			className={css`
				background: ${bgColor};
				height: 100%;
				cursor: pointer;
			`}
			onClick={() => {
				dispatch(
					setHasSeenPrelaunch(true),
				);
			}}
		>
			<ContentFrame
				isPrelaunch
				closeButton={closeButton}
				// color={contentFrameColor}
			>
				<PrelaunchContent />
				<p className={css`text-align: center;`}>
					<span
						onClick={() => {
							dispatch(
								setHasSeenPrelaunch(true),
							);
						}}
						className={css`
							cursor: pointer;
							font-weight: bold;
							border-bottom: solid 1px black;
						`}
					>
						ENTER
					</span>
				</p>
			</ContentFrame>
		</div>;
	}

	return <Router basename={process.env.URL_PREFIX}>
		<div
			className={css`
				background: ${bgColor};
				height: 100%;
			`}
		>
			{/*<Route
				render={({ history }) => {
					if (!locationListening) {
						history.listen((location, action) => {
							console.log(location, action);
						});
						locationListening = true;
					}
				}}
			/>*/}

			{/* always display these: */}
			<Route
				render={_props => {
					const {
						history,
						location: { pathname },
					} = _props;
					// const anyActivitySelected = [
					// 	paths.RESEARCH_PROJECTS,
					// 	paths.MASTER_LECTURES,
					// 	paths.RESEARCH_INITIATIVES,
					// 	paths.AM_VR,
					// 	paths.AIR,
					// 	paths.PUBLIC_EXPOSURE,
					// 	paths.IMPACT,
					// ].includes(pathname);

					return <Fragment>
						<MainMenu
							dispatch={dispatch}
							pathname={pathname}
							dataLoaded={dataLoaded}
							history={history}
						/>

						<ActivitiesFilterMenu
							dispatch={dispatch}
							pathname={pathname}
							seenIntroPathnames={seenIntroPathnames}
						/>

						<VisualizationModeMenu
							dispatch={dispatch}
							pathname={pathname}
							showCloseButton={false}
							// showCloseButton={anyActivitySelected}
						/>

						<If condition={dataLoaded}>
							<div
								className={css`
									width: 100%;
									height: 100%;
								`}
							>
								<ThreeCanvas
									dispatch={dispatch}
									pathname={pathname}
									history={history}
									contentIsOpen={!!contentFrameContent}
								/>
							</div>
						</If>

						<div
							className={css`
								position: fixed;
								left: 1rem;
								bottom: 1rem;
								z-index: 99999;
							`}
						>
							<InteractionLegend
								zoomEnabled={enabledInteractions.zoomEnabled}
								panningEnabled={enabledInteractions.panningEnabled}
								rotationEnabled={enabledInteractions.rotationEnabled}
							/>
						</div>

						<If condition={contentFrameContent}>
							<ContentFrame
								closeButton={closeButton}
								color={contentFrameColor}
								isIntro={contentFrameIsIntro}
							>
								{contentFrameContent}
							</ContentFrame>
						</If>
					</Fragment>;
				}}
			/>
		</div>
	</Router>;
};

App.propTypes = {
	dispatch: PropTypes.func.isRequired,
	dataLoaded: PropTypes.bool.isRequired,
	contentFrameContent: PropTypes.any,
	contentFrameColor: PropTypes.any,
	contentFrameIsIntro: PropTypes.bool,
	seenIntroPathnames: PropTypes.array,
	enabledInteractions: PropTypes.object.isRequired,
	hasSeenPrelaunch: PropTypes.bool.isRequired,
};

App.defaultProps = {
};

export default App;
