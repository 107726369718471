import { H1, H2 } from '../../styles';


const IntroImpactQuality = (props) => {
	return <Fragment>
		<H1>IMPACT / QUALITY</H1>
		<p>Impact and quality are intrinsically connected but they often cover different features of the same elements or activities. Impact is a more tangible category than quality, as it can be validated through the traces the artistic research leaves (festival screenings, exhibitions, publications etcetera). Quality, on the other hand, is an evaluation of the process. There’s a correlation between the two, as impact if often a proof of quality, but multiple elements that constitute a qualitative process do not directly translate into tangible results.</p>

		<H2>Quality</H2>
		<p>Evaluation of the quality of the different processes by means of recognition of their value by the active agents and institutions in the different fields (accreditation results of the programme; selection and screenings, awards, residencies, scholarships, requests to teach or participate in PhD programmes, conferences, etcetera).</p>

		<H2>Impact</H2>
		<p>Tangible results of the artistic research in and through cinema as validated by the professional fields and their criteria (festival screenings, exhibitions, publications etcetera).</p>

		<p>All references here are to alumni and current students/researchers. Except for ‘team&apos;s successful grants’ and ‘publications’ because they also include AIRs and lectors.</p>
	</Fragment>;
};

// IntroImpactQuality.propTypes = {
// };

// IntroImpactQuality.defaultProps = {
// };

export default IntroImpactQuality;
