import { H1, H2, H3 } from '../../styles';


const IntroResearchInitiatives = (props) => {
	return <Fragment>
		<H1>RESEARCH INITIATIVES</H1>
		<p>The Lectoraat in Film, as the research unit of the Film Academy, has over time initiated and provided the context for different research initiatives with respect to both curriculum development (master, bachelor and interdisciplinary education) and short term artistic research projects (the LAB).</p>

		<H2>Educational Curriculum R&D</H2>

		<H3>The master programme</H3>
		<p>The master programme has since its inception in 2009 been a space for continuous reflection on both artistic research and film pedagogy. This led in 2014-2015 to a new framework for the course, as a result of which the ‘Master of Film’ got transformed into the Master ‘Artistic research in and through Cinema’ to better accommodate the needs of the student-researchers and the field.</p>

		<H3>VRacademy</H3>
		<p>The VRAcademy, initiated in 2017, as a teaching and research initiative, aims to further develop the XR (Extended Reality) research and curriculum of the Film Academy and share its expertise with the other schools at the Amsterdam University of the Arts (AHK).</p>

		<H3>The Bridge [Comenius grant - (A)]</H3>
		<p>Having been granted a 2-year Senior Fellow Comenius grant, the Lectoraat is presently conducting a research project consisting of two parts, of which the ‘Online Platform for Research in and through Cinema’ will act as a hub that will generate knowledge and experience further transforming the MA programme.</p>

		<H3>The LAB</H3>
		<p>The LAB-initiative provided Film Academy teachers with the possibility to undertake short term artistic research projects stemming from their interests as teachers and makers, which if relevant could later be developed further beyond the LAB.</p>

		<H3>Interdisciplinary Atelier Media & Imagination</H3>
		<p>Understanding that excelling in a single disciplinary field may no longer suffice to respond to increasingly complex challenges and create originally, Vesna Petresin’s research project seeks to determine a sustainable way to develop transdisciplinary education at the Amsterdam University of the Arts (AHK).</p>
	</Fragment>;
};

// IntroResearchInitiatives.propTypes = {
// };

// IntroResearchInitiatives.defaultProps = {
// };

export default IntroResearchInitiatives;
