export const activitiesPrefix = '/category';

export default {
	INDEX: '/',
	SEARCH: '/search',

	// ABOUT: '/about',
	// FUTURE: '/future-plans',
	// SEARCH: '/search',

	TOPICS: '/topics',
	LOCATIONS: '/locations',
	CHRONOLOGY: '/chronology',

	RESEARCH_PROJECTS: `${activitiesPrefix}/research-projects`,
	MASTER_LECTURES: `${activitiesPrefix}/master-lectures`,
	RESEARCH_INITIATIVES: `${activitiesPrefix}/research-initiatives`,
	AM_VR: `${activitiesPrefix}/im-vra`,
	AIR: `${activitiesPrefix}/air`,
	PUBLIC_EXPOSURE: `${activitiesPrefix}/public-exposure`,
	IMPACT: `${activitiesPrefix}/impact-quality`,
};
