import * as JsSearch from 'js-search';
import deburr from 'lodash.deburr';

import { fieldNames } from './data';


export const createSearch = (allDataItems) => {
	const search = new JsSearch.Search('_id');
	search.tokenizer = {
		tokenize(text) {
			const ret = text.split(/[,. -;:!?]/)
				.map(deburr); // removes accents and diacritics
			return ret;
		},
	};
	search.addIndex(fieldNames.names);
	search.addIndex(fieldNames.title);
	search.addIndex(fieldNames.tags);
	search.addDocuments(allDataItems);
	return search;
};
