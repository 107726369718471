import R from 'ramda';
// import { useState } from 'react';

import { margins, responsizeFontSize, breakPointL } from '../styles';
import { setContent } from '../actionCreators';
import categories from '../categories';
import paths from '../paths';

// import hamburgerIcon from '../../assets/hamburger.svg';
// import hamburgerIconOpen from '../../assets/hamburger-open.svg';

import MenuItem from './MenuItem';
import About from './content/About';
import FuturePlans from './content/FuturePlans';


const style = css`
	position: fixed;
	top: 0;
	left: 0;
	padding: ${margins.m};
	z-index: 99;
	&, input {
		${responsizeFontSize.topMenu}
	}
`;

const MainMenu = ({ dispatch, pathname, dataLoaded, history }) => {
	// const [hamburgerOpen, setHamburgerOpen] = useState(false);
	const cat = R.find(R.propEq('path', pathname))(categories);

	return <div className={style}>
		{/*<div
			className={css`
				margin-bottom: 1em;
				cursor: pointer;
				user-select: none;
			`}
			// onClick={() => {
			// 	setHamburgerOpen(!hamburgerOpen);
			// }}
		>
			<MenuItem
				noMargin
				to={paths.INDEX}
				disabled={paths.INDEX === pathname}
			>
				Home
			</MenuItem>
		</div>*/}

		<div>
			<MenuItem
				noLink
				onClick={() => {
					dispatch(
						setContent({
							content: <About />,
							color: null,
						}),
					);
				}}
			>
				About
			</MenuItem>
		</div>
		<div>
			<MenuItem
				noLink
				onClick={() => {
					dispatch(
						setContent({
							content: <FuturePlans />,
							color: null,
						}),
					);
				}}
			>
				Future plans
			</MenuItem>
		</div>
		<div
			className={css`
				@media (max-width: ${breakPointL}) {
					display: none;
				}
			`}
		>
			<input
				disabled={!dataLoaded}
				type="text"
				placeholder="Search"
				className={css`
					color: black;
					background: transparent;
					width: 170px;
					@media (max-width: ${breakPointL}) {
						width: 100px;
					}
					outline: none;
					border: none;
					border-bottom: solid 2px black;

					::placeholder {
						color: black;
					}

					&:focus::placeholder {
						opacity: 0.3;
					}

					${(!dataLoaded)
						? `
							pointer-events: none;
							opacity: 0.3;
						`
						: ''
					}
				`}
				onKeyDown={(event) => {
					event.stopPropagation();
					if (event.keyCode === 13) { // enter
						window.query = event.target.value;
						if (pathname !== paths.SEARCH) {
							window.returnToPath = pathname;
						}
						// eslint-disable-next-line fp/no-mutating-methods
						history.push(paths.SEARCH);
					}
				}}
			/>
			<br />
			<br />
		</div>

		<If condition={cat}>
			<div>
				<MenuItem
					noLink
					onClick={() => {
						dispatch(
							setContent({
								content: cat.introText,
								color: cat.bgColor,
								isIntro: true,
								pathname: cat.path,
							}),
						);
					}}
				>
					<span
						className={css`
							color: ${cat.color};
							text-transform: uppercase;
							${responsizeFontSize.bottomMenu}
						`}
					>
						About {cat.label}
					</span>
				</MenuItem>
			</div>
		</If>
	</div>;
};

MainMenu.propTypes = {
	dispatch: PropTypes.func.isRequired,
	pathname: PropTypes.string.isRequired,
	dataLoaded: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
};

// MainMenu.defaultProps = {
// };

export default MainMenu;
