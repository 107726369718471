import { Link } from 'react-router-dom';

import { responsizeFontSize, bgColor, backdropBlur } from '../styles';


const margin = '3px';
const linkStyle = css`
	${responsizeFontSize.bottomMenu}
	font-weight: 500;
	margin-left: ${margin};
	margin-right: ${margin};
	padding-left: 7px;
	padding-right: 7px;
	padding-bottom: 4px;
	padding-top: 4px;
	text-decoration: none;
	text-transform: uppercase;
	border: solid 1px black;
	display: inline-block; /* needed for backdrop blur */
`;


const SecondaryMenuItem = (props) => {
	const {
		children,
		to,
		color,
		isSelected,
		onClick,
		noMargin,
		disabled,
		onMouseEnter,
		onMouseLeave,
	} = props;

	return <Link
		to={to}
		draggable={false}
		onClick={onClick}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		className={cx(
			linkStyle,
			(noMargin) ? css`margin: 0;` : undefined,
			css`
				opacity: ${(disabled) ? 0.3 : 1};
				${(disabled) ? 'pointer-events: none;' : ''}

				border-color: black;
				:hover {
					border-color: ${color};
				}
			`,
			(isSelected)
				? css`
					color: white;
					border-color: ${color};
					background: ${color};
				`
				: css`
					color: black;
					background: ${bgColor};
					${backdropBlur}
				`,
		)}
	>
		{children}
	</Link>;
};

SecondaryMenuItem.propTypes = {
	children: PropTypes.any.isRequired,
	to: PropTypes.any.isRequired,
	color: PropTypes.string,
	isSelected: PropTypes.bool.isRequired,
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	noMargin: PropTypes.bool,
	disabled: PropTypes.bool,
};

SecondaryMenuItem.defaultProps = {
	color: 'black',
	noMargin: false,
};

export default SecondaryMenuItem;
