import { H1, H2 } from '../../styles';


const IntroResearchProjects = (props) => {
	return <Fragment>
		<H1>RESEARCH PROJECTS</H1>
		<p>The master programme constitutes a framework for the development of research interests and questions of the student-researchers. Performed ‘in and through cinema’ – using the frame, notions and paradigm of the filmmaking practice - these practice-driven trajectories of the student-researchers produce bodies of research in their own right. Their research projects contribute both to the development of filmmaking / art practice and to the understanding of the social-political phenomena.</p>

		<H2>In and through Cinema</H2>
		<p>Research ‘in and through cinema’ refers to research contributing at the same time to the development of the filmmaking practice as to knowledge areas outside of the arts.</p>

		<H2>In Cinema</H2>
		<p>The research ‘in cinema’ revolves around questions of the cinema practice itself, such as spectatorship, perception, storytelling, cognition, modes of production, the boundaries between fiction & documentary, ethics, etc.</p>

		<H2>Through Cinema</H2>
		<p>Research ‘through cinema’ signifies the exploration of topics and fields beyond cinema and artistic practice itself, such as memory, archive practices, gender and queer, addiction, work, forgiveness, belief, trauma, political activism, surveillance, big data collection, pedagogy, the anthropocene and many others.</p>
	</Fragment>;
};

// IntroResearchProjects.propTypes = {
// };

// IntroResearchProjects.defaultProps = {
// };

export default IntroResearchProjects;
