import R from 'ramda';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import { dataLoaded } from './actionCreators';
import reducer, { initialState } from './reducer';
import App from './components/App';

import { logBanner } from './utils';


logBanner();

const logger = createLogger({
	collapsed: true,
	timestamp: false,
});

const store = createStore(
	reducer,
	initialState,
	applyMiddleware(
		thunk,
		logger,
	),
);
const ConnectedApp = connect(R.identity)(App);

ReactDOM.render(
	<Provider store={store}>
		<ConnectedApp />
	</Provider>,
	document.getElementById('app'),
);


import(
	/* webpackChunkName: "data-by-category" */
	'../data/dataByCategory.json' // eslint-disable-line comma-dangle
).then((dataByCategory) => {
	store.dispatch(
		dataLoaded(dataByCategory.default),
	);
});
