import { H1, H2 } from '../../styles';


const IntroPublicExposure = (props) => {
	return <Fragment>
		<H1>PUBLIC EXPOSURE</H1>
		<p>Sharing the process and outcomes of the research and development of the many different projects under the heading of the Lectoraat in Film is an essential aspect of ‘artistic research in and through cinema’. This ‘public exposure’ takes shape through the annual graduation event - the Artistic Research Week - as well as through publications, successful funding applications and other professional and public events involving student-researchers, teachers, Artists in Residence and the professors or ‘lectors’ of the Lectoraat.</p>

		<H2>Artistic Research Week</H2>
		<p>Annual presentations and discussions of the research projects of the graduating MA student-researchers.</p>

		<H2>Professional events Artistic Research Week</H2>
		<p>Since 2017, the annual Artistic Research Week also includes events directed at the professional fields of filmmaking and research.</p>

		<H2>Publications</H2>
		<H2>Professional and public events, conferences and talks (lectors)</H2>
		<H2>Successful grants and funds applications</H2>
	</Fragment>;
};

// IntroPublicExposure.propTypes = {
// };

// IntroPublicExposure.defaultProps = {
// };

export default IntroPublicExposure;
