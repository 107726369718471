export default {
	air: 'AIR',
	'im-vra': 'IM / VRA',
	'impact-quality': 'Impact / quality',
	'impact': 'Impact',
	'quality': 'Quality',
	'master-lectures': 'Master lectures',
	'public-exposure': 'Public exposure',
	'research-initiatives': 'Research initiatives',
	'research-projects': 'Research projects',

	'consultancy-and-advisory': 'Consultancy and advisory',
	'customised-ws-and-education-activities': 'Customised WS and education activities',
	'inter-institutional-projects-partnership': 'Inter-institutional Projects / Partnership',
	'public-private-projects-partnership': 'Public-Private Projects / Partnership',
	'succesful-grants-and-funds-applications': 'Succesful grants and funds applications',
	'alumni-teaching-and-phd': 'Alumni teaching and PhD',
	'prestentation-in-professional-events': 'Presentation in professional events',
	publications: 'Publications',
	'selection-to-festivals-shows': 'Screenings, festivals, exhibitions',
	'awards-prizes-honors-grants': 'Awards, prizes, grants',
	'education-teaching-and-phd-alumni': 'Education, Teaching and PhD (alumni)',
	// 'selection-to-festivals-shows': '',
	'team-s-successful-grants': 'Team\'s successful grants',
	'artistic-research-week': 'Artistic Research Week',
	'consultancy-and-advice-mieke': 'Consultancy and advice Mieke Bernink',
	'consultancy-and-advice-professor-eyal-sivan': 'Consultancy and advice Eyal Sivan',
	'professional-events-arw': 'Professional events (ARW)',
	'professional-public-events-conf-and-talks': 'Professional & public events Eyal Sivan',
	'public-events-conferences-and-talks': 'Public events and conferences Mieke Bernink',
	'public-events-conferences-an-talks': 'Public events, conferences and talks',
	// 'publications': '',
	'corriculum-rd': 'Curriculum R&D',
	'the-bridge': 'The Bridge (Comenius Senior Fellow 2018)',
	'the-lab-projects': 'The LAB Projects',
	'in-and-through-cinema': 'In and through Cinema',
	'in-cinema': 'In Cinema',
	'through-cinema': 'Through Cinema',
};
