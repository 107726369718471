import { H1, UL } from '../../styles';


const About = (props) => {
	return <Fragment>
		<H1>ABOUT</H1>
		<p>The Lectoraat in Film was incubated in the Master programme “Artistic Research in and through Cinema”. The programme and the initiatives surrounding it such as the VR Academy, Artists in Residence, Master Lectures et cetera, constituted a practical matrix for researching the modes of education of ‘artistic research in and through cinema’ and its potential use beyond the cinema itself and other visual arts.</p>

		<p>In a world dominated by images and sounds, where audio-visual production becomes a daily mode of learning, communication and exchange, and in which cinematic, audio-visual media practices are becoming more accessible, the Lectoraat in Film poses a question about the function of film education, and the socio-political perspectives opened by artistic research in and through cinema.</p>

		<H1>CREDITS</H1>
		<UL>
			<li>An Idea by: EYAL SIVAN</li>
			<li>Project leader: EYAL SIVAN, MIEKE BERNINK</li>
			<li>Concept: EYAL SIVAN, NINA JAN (Alumnus G2015), MARIA MOLINA PEIRÓ (Alumnus G2017)</li>
			<li>Design: MARIA MOLINA PEIRÓ</li>
			<li>Creative Programming: <a href="http://rulesrulesrules.studio/" target="_blank">FREDERIC BRODBECK</a></li>
			<li>Project coordination: NINA JAN</li>
			<li>Texts: STANISLAW LIGUZIŃSKI (Alumnus G2016)</li>
			<li>Technical consultants: BRAM LOOGMAN (Alumnus G2015), MARC TIEMISSEN</li>
			<li>Video editing: KRISTINA DAUROVA (Alumnus G2017)</li>
			<li>Archiving and tagging: NINA JAN, EYAL SIVAN, MIEKE BERNINK, KRIS DEKKERS, SABIEN SCHÜTTE</li>
		</UL>

		<p>This project was produced in the frame of the The Bridge research project (Comenius senior fellowship).</p>
	</Fragment>;
};

// About.propTypes = {
// };

// About.defaultProps = {
// };

export default About;
