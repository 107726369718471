import { H1, H2, H3 } from '../../styles';


const IntroAIR = (props) => {
	return <Fragment>
		<H1>ARTISTS IN RESIDENCE</H1>
		<p>The Lectoraat in Film has over the years invited different artists in residence. These selected residents – filmmakers or artists engaged in a research-driven practice - were all invited in a threefold capacity: as makers actively developing their latest projects; as workshop teachers or mentors working with the students of the Master, and where possible, BA students; and as consultants giving their input on the timely matter of research-based education in and through cinema.</p>
	</Fragment>;
};

// IntroAIR.propTypes = {
// };

// IntroAIR.defaultProps = {
// };

export default IntroAIR;
