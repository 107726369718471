// import { H1, H2 } from '../../styles';


const PrelaunchContent = (props) => {
	return <Fragment>
		<p>Dear visitor,</p>

		<p>Welcome to the interactive website of the Lectoraat Film!</p>

		<p>Before you begin your journey through our past and present and glimpse our plans for the future, we would like to give you some tips and tricks to successfully manoeuvre through the website content.</p>

		<ul
			className={css`
				> li:not(:last-child) {
					margin-bottom: 1em;
				}
			`}
		>
			<li>The website in front of you is not a traditional website. It is set in a 3D environment, which means that you can literally move through its content, zoom in, zoom out, flip the content clusters around, move left, right, up and down using your mouse, keyboard or arrows on the website. Feel free to play around!</li>
			<li>Due to the 3D nature of the website, a huge amount of data and the fact that the website is still a “work in progress”, the website occasionally freezes. This can currently be solved by refreshing the page in question.</li>
			<li>Keep in mind that for this occasion we have chosen to present only 29 research projects by the MA student-researchers, which represents only about 30 % of the total student research projects done in the past years.</li>
			<li>Some publications published by the Lectoraat are currently not accessible online, but they will be made available to you during your visit.</li>
			<li>Some Vimeo players available on the website appear to be unavailable for viewing. However, if you click on the link to watch in on the Vimeo website, they often work normally.</li>
		</ul>

		<p>We wish you a pleasant journey.</p>
	</Fragment>;
};

// PrelaunchContent.propTypes = {
// };

// PrelaunchContent.defaultProps = {
// };

export default PrelaunchContent;
